import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NewslettersItem from '../../newsletters/NewslettersItem';
import { togglePropsColorPalette } from '../../../util/helperFuncs';
import { MODAL_TYPE_SUBSCRIBE_FORM } from '../../../actions/types';
import { showModal } from '../../../actions/modalActions';

const NewslettersCommon = (props) => {
  const { profile } = props;
  const showNotification = () => {
    props.showModal(MODAL_TYPE_SUBSCRIBE_FORM, {
      headshot: profile.headshot_image,
      fullName: profile.full_name,
      company: profile.info.company,
      form_key: profile.form_key,
    });
  };
  return (
    <div data-testid="newsletters-subcomponent" className="subhead">
      {profile.newsletters && (
        <div className="row">
          <div className="col header-links-component text-center">
            <h2 data-testid="subhead-title">Newsletters</h2>
            <p className="pagelink text-center"><Link to="/newsletters" style={togglePropsColorPalette(props, 'link')}>Show More</Link></p>
            <p className="pagelink"><span className="divider" style={togglePropsColorPalette(props, 'divider')} /> </p>
            <div
              className="pagelink text-center"
              style={togglePropsColorPalette(props, 'link')}
              onClick={showNotification}
              role="button"
            >Subscribe to Newsletter
            </div>
          </div>
          <div className="container">
            <div className="row newsletters">
              {profile.newsletters.map(newsletter => (
                <NewslettersItem
                  key={newsletter.campaign.id}
                  campaignId={newsletter.campaign.id}
                  imageUrl={newsletter.image_url}
                  campaignName={newsletter.campaign.name}
                  shareDescription={newsletter.campaign.share_description}
                  campaignDate={newsletter.campaign_date}
                  onlineUrl={newsletter.campaign.newsletter_online_url}
                  linkColor={togglePropsColorPalette(props, 'link')}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
    /* <!-- Newsletters End --> */
  );
};


NewslettersCommon.propTypes = {
  profile: PropTypes.shape({
    newsletters: PropTypes.array,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(null, { showModal })(NewslettersCommon);
