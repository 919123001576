import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import Parser from 'html-react-parser';
import { getSingleNewsletter } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import CTA from '../common/CTA/CTA';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import { showModal } from '../../actions/modalActions';
import { initGA, logPageView } from '../common/Google/ReactGA';
import { loadTitle } from '../../util/helperFuncs';

class SingleNewsletter extends Component {
  componentDidMount = async () => {
    await this.getCampaign();
    initGA();
    logPageView();
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this.getCampaign();
    }
  }

  getCampaign() {
    const { getSingleNewsletter, location: { pathname } } = this.props;
    getSingleNewsletter(pathname);
    window.scrollTo(0, 0);
  }

  render() {
    const {
      profile: {
        profile, profile: { color_palette }, singlenewsletter, loading,
      },
    } = this.props;
    const { info: { newsletter_page_title } } = profile;
    let singlenewsletterContent;

    if (profile === null || singlenewsletter === null || loading) {
      singlenewsletterContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      const {
        use_alt_content, single_newsletter: {
          image_url,
          campaign_date,
          campaign: { headline, content, content2 },
        },
      } = singlenewsletter;

      const { page_title, show_reviews } = profile;
      singlenewsletterContent = (
        <DocumentTitle title={loadTitle(page_title, `${headline}`)}>
          <div>
            <HeaderSmall profile={profile}>
              <Greeting title={newsletter_page_title} subheading="" profile={profile} />
            </HeaderSmall>
            <main className="container">
              <CTA profile={profile} colorPalette={color_palette} />
              <div className="row subhead">
                <div className="col text-center">
                  <h1>{headline}</h1>
                  <h3>{campaign_date}</h3>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col text-center">
                  {singlenewsletter && singlenewsletter.single_newsletter && image_url && (
                    <img
                      src={image_url}
                      alt="article-content"
                      className="single-newsletter-image"
                    />
                  )}
                </div>
              </div>
              <div className="row mb-5 mt-5">
                <div className="col campaign-content">
                  {!use_alt_content && content ? Parser(content) : Parser(content2)}
                </div>
              </div>
              <NewslettersCommon profile={profile} colorPalette={color_palette} />
              {
                show_reviews
                  ? (
                    <div className="container">
                      <ReviewsCommon profile={profile} colorPalette={color_palette} />
                    </div>
                  ) : null
              }
            </main>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <div className="single-newsletter">
        {singlenewsletterContent}
      </div>
    );
  }
}

SingleNewsletter.propTypes = {
  getSingleNewsletter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  singlenewsletter: state.profile.singlenewsletter,
});

export default connect(mapStateToProps, { showModal, getSingleNewsletter })(SingleNewsletter);
