import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfile } from '../../../../actions/profileActions';
import CustomLink from '../../CustomLinks/CustomLink';
import CustomPageLink from '../../CustomLinks/CustomPageLink';
import { toggleProfileColorPalette } from '../../../../util/helperFuncs';

const uuidv4 = require('uuid/v4');

class NavBar extends Component {
  render() {
    const { profile: { profile, loading } } = this.props;

    let navBarContent;

    if (profile === null || loading) {
      navBarContent = null;
    } else {
      const {
        custom_header_links,
        show_reviews,
        color_palette,
        info: {
          contact_nav_title,
          custom_page_navbar_title,
          custom_page_enabled,
          custom_page_url,
          reviews_nav_title,
          video_page_active,
          video_page_nav_title,
          newsletters_active,
          newsletters_nav_title,
          home_nav_title,
        },
        user: {
          vertical,
          promo_catalog_url,
        },
      } = profile;

      const classNames = `${color_palette}` ? 'navbar navbar-expand-xl fixed-top navbar-dark' : 'navbar navbar-expand-xl navbar-dark bg-dark fixed-top';

      navBarContent = (
        <nav className={classNames} style={toggleProfileColorPalette(profile, 'navbar')}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExampleDefault">
            <ul className="navbar-nav ">
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <NavLink className="nav-link" exact to="/">{home_nav_title}<span className="sr-only">(current)</span></NavLink>
              </li>
              { newsletters_active
                && (<li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink className="nav-link" to="/newsletters">{newsletters_nav_title}</NavLink></li>)
              }
              { show_reviews ? <li className="nav-item"><NavLink to="/reviews" className="nav-link">{reviews_nav_title}</NavLink></li> : null }
              { video_page_active
                && (<li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/videos" className="nav-link">{video_page_nav_title}</NavLink></li>)
              }
              {
                vertical === 20
                && (
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link" to="/joinourteam">Join Our Team</NavLink>
                </li>
                )
              }
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/contact" className="nav-link">{contact_nav_title}</NavLink></li>
              {vertical === 6 && promo_catalog_url
                && (<li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a className="nav-link" href={promo_catalog_url} target="_blank" rel="noopener noreferrer">Catalog</a></li>)
              }
              {
                custom_page_enabled
                && custom_page_navbar_title
                && (
                  <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <CustomPageLink title={custom_page_navbar_title} url={custom_page_url} vertical={vertical} class="nav-link" />
                  </li>
                )
              }
              {
                custom_header_links
                && custom_header_links.length !== 0
                && custom_header_links.map(link => (
                  <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" key={uuidv4()}>
                    <CustomLink path={link.path} label={link.label} class="nav-link" />
                  </li>
                ))
              }
            </ul>
          </div>
        </nav>
      );
    }
    return (
      <div>{navBarContent}</div>
    );
  }
}

NavBar.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default withRouter(connect(mapStateToProps, { getProfile })(NavBar));
