import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { Field, reduxForm } from 'redux-form';
import Spinner from '../Spinner';
import { postProfileFormRequest } from '../../../actions/profileActions';
import normalizePhone from '../../../util/normalizePhone';
import { hideModal, showModal } from '../../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../../actions/types';
import Modal from './Modal';
import { toggleProfileColorPalette, isOEDomain } from '../../../util/helperFuncs';
import ReCaptchaV2 from '../Google/ReCaptchaV2';

class ContactGeneralFormModal extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    formValues: null,
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  submitForm = () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = async (values) => {
      const { profile: { contact, campaign_id } } = this.props.profile;
      const { postProfileFormRequest, showModal } = this.props;
      const { form_key } = this.state;

      const obj = {
        form_key: '',
        form_name: '',
        lead_trigger_type_id: 19,
        form_data: {},
      };

      const form = document.getElementsByTagName('form');
      const formName = form[1].name; // Use 2nd form index as the modal comes after the footer form in the DOM structure
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.form_data = Object.assign(obj.form_data, values);
      obj.form_name = formName;

      await postProfileFormRequest(obj);

      showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
    };

    return onCreateSubmitObj(values);
  }

  onClose = () => {
    hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  };

  renderPhoneSelectField = (field) => {
    // Select options for phone type
    const phoneOptions = [
      { label: 'Choose one', value: '' },
      { label: 'Home', value: 1 },
      { label: 'Mobile', value: 2 },
      { label: 'Work', value: 3 },
      { label: 'Other', value: 4 },
    ];

    const selectOptions = phoneOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group phone-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">Phone type</label><i className="bar" />
      </div>
    );
  }

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderModalEmailField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group modal-email">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderPhoneField = field => (
    <div className="form-group">
      <input
        {...field.input}
        type={field.type}
        id={field.id}
        className={field.className || ''}
        required={field.required}
      />
      <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
    </div>
  );

  renderTextAreaField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    return (
      <div className="form-group general-inquiry">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
          rows="3"
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label>
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderTextAreaFieldReferFriend = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group refer-friend-message">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderReferFriendEmailField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group refer-friend-email">
        <input
          {...field.input}
          type="email"
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  render() {
    const onClose = () => {
      hideModal();

      if (afterClose) {
        afterClose();
      }
    };

    const {
      profile,
      loading,
      headshot,
      fullName,
      company,
      hideModal,
      afterClose,
      handleSubmit,
      submitting,
      invalid,
    } = this.props;

    const { btnDisabled } = this.state;

    const url = profile.profile.user.landing_page_host;
    const isCustomDomain = !isOEDomain(url);

    const handleSubmitBtnClick = () => {
      // Get form key from the profile
      this.setState({ form_key: this.props.form_key }); // Also set button back to disabled

      // Function to allow simultaneous modal trigger and reading of form field values
      // Sets a buffer timeout to allow values to be read before resetting form fields
      // Manually reset dropdown fields as the reset() only clears all other input fields
      const withBufferTimeout = () => {
        setTimeout(() => {
          this.setState({ btnDisabled: true });
        }, 1000);
      };

      withBufferTimeout();
    };

    const contactGeneralFormContent = loading
      ? (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <Modal
          profile={profile}
          headshot={headshot}
          onClose={onClose}
        >
          <div className="modal-form container">
            <div className="flex-row-reverse">
              <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row user-info-row">
              <div className="col text-center">
                {headshot && (
                  <div className="headshot-image mx-auto">
                    <img
                      src={headshot}
                      alt=""
                    />
                  </div>
                )}
                <div className="header-info">
                  <p className="client-name">{fullName && Parser(fullName)} {company && ' - '} {company && Parser(company)}</p>
                </div>
              </div>
            </div>
            {/* <!-- Contact Form Start --> */}
            <div className="row">
              <div className="col text-left">
                <small>* = required fields</small>
                {/* TODO: Fix required on all Field tags as they are all in place to fix a bug that raises the labels by default */}
                <form id="contactV2modal" name="contactV2modal" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                  <div className="container modal-form">
                    <div className="row">
                      <React.Fragment>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          component={this.renderModalEmailField}
                          for="email"
                          className="email"
                          label="Email Address*"
                          required
                        />
                      </React.Fragment>
                    </div>
                  </div>
                  <React.Fragment>
                    <div className="container modal-form">
                      <div className="row">
                        <Field
                          type="text"
                          name="first_name"
                          id="first-name"
                          component={this.renderField}
                          for="first_name"
                          className=""
                          label="First Name*"
                          required
                        />
                        <Field
                          type="text"
                          name="last_name"
                          id="last-name"
                          component={this.renderField}
                          for="last_name"
                          className=""
                          label="Last Name"
                          required
                        />
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="container modal-form">
                      <div className="row">
                        <Field
                          type="tel"
                          name="phone"
                          id="phone"
                          component={this.renderPhoneField}
                          for="phone"
                          className=""
                          label="Phone"
                          normalize={normalizePhone}
                          required
                        />
                        <Field
                          type="select"
                          name="phone_type"
                          id="phone-type"
                          component={this.renderPhoneSelectField}
                          for="phone_type"
                          label={this.selectOptions}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                  {/* GENERAL INQUIRY START */}
                  <div className="container modal-form">
                    <div className="row">
                      <Field
                        type="text"
                        name="message"
                        id="general-inquiry"
                        component={this.renderTextAreaField}
                        for="message"
                        className=""
                        label="Add a message"
                        required
                      />
                    </div>
                  </div>
                  {/* GENERAL INQUIRY END */}

                  {!isCustomDomain && (
                    <ReCaptchaV2
                      onVerify={this.submitForm}
                      reCaptchaRef={this.reCaptchaRef}
                    />
                  )}

                  <div className="row">
                    <div className="col text-center submit-btn-wrapper mb-3">
                      <p>By clicking 'Send Message', you agree to be contacted using the contact information you have provided above.</p>
                      <button
                        className="contact-btn btn btn-large"
                        type="submit"
                        name="action"
                        onClick={handleSubmitBtnClick}
                        disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                        style={toggleProfileColorPalette(profile.profile, 'contactButton')}
                      >Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Contact Form End --> */}

          </div>
        </Modal>
      );

    return (
      <div>
        {contactGeneralFormContent}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.referrer_first_name) {
    errors.referrer_first_name = 'First name is required';
  }
  if (values.first_name && (values.first_name.includes('<') || values.first_name.includes('&lt;'))) {
    errors.first_name = 'Invalid text entered';
  }
  if (values.last_name && (values.last_name.includes('<') || values.last_name.includes('&lt;'))) {
    errors.last_name = 'Invalid text entered';
  }
  if (values.message && (values.message.includes('<') || values.message.includes('&lt;'))) {
    errors.message = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  if (!values.referrer_email) {
    errors.referrer_email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.referrer_email)) {
    errors.referrer_email = 'Valid email required.';
  }

  return errors;
};

ContactGeneralFormModal.defaultProps = {
  loading: false,
};

ContactGeneralFormModal.propTypes = ({
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  headshot: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
});

const mapStateToProps = state => ({
  profile: state.profile,
});

export default compose(
  connect(mapStateToProps, { hideModal, postProfileFormRequest, showModal }),
  reduxForm({ validate, form: 'ContactForm' }),
)(ContactGeneralFormModal);
