import React from 'react';

const TextOnly = props => (
  <div className="row text-layout">
    <div className="col text-center">
      <div className="text-only mx-auto">{props.text}</div>
    </div>
  </div>
);

export default TextOnly;
