import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfile, feedbackSuccess } from '../../actions/profileActions';
import SuccessPage from './SuccessPage';

class FeedbackSuccess extends Component {
  componentDidMount() {
    const { feedbackSuccess, location: { pathname, search } } = this.props;
    const feedbackPath = `${pathname}${search}`;
    feedbackSuccess(feedbackPath);
  }

  render() {
    const { profile: { profile } } = this.props;
    return (
      <div className="container">
        <SuccessPage profile={profile} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile, feedbackSuccess })(FeedbackSuccess);
