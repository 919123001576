import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const HeaderOverlay = ({ children, className, show }) => (
  <div className={classNames({ 'header-overlay': show }, 'row', className)}>
    {children}
  </div>
);

HeaderOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  className: PropTypes.string,
};

HeaderOverlay.defaultProps = {
  show: false,
  className: null,
};

export default HeaderOverlay;
