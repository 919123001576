import axios from 'axios';
import {
  GET_PROFILE,
  GET_HOME,
  GET_REVIEWS,
  POST_CONTACT_REQUEST,
  POST_REVIEW_REQUEST,
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING,
  GET_VIDEOS,
  GET_SINGLE_NEWSLETTER,
  GET_NEWSLETTERS,
  GET_UNSUBSCRIBE,
  POST_RESUBSCRIBE_REQUEST,
  GET_FEEDBACKSUCCESS,
  GET_CUSTOMPAGE,
  SHOW_MODAL,
  MODAL_TYPE_SUBMIT_SUCCESS,
} from './types';

// List actions needed for profile as exportable pure functions
// Get Profile
export const getProfile = () => (dispatch) => {
  axios.get('/v2/base')
    .then(res => dispatch({
      type: GET_PROFILE,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_PROFILE,
      payload: {},
    }));
};

// Get Home
export const getHome = () => (dispatch) => {
  axios.get('/v2/home')
    .then(res => dispatch({
      type: GET_HOME,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_HOME,
      payload: {},
    }));
};

// Get Reviews
export const getReviews = () => (dispatch) => {
  axios.get('/v2/reviews')
    .then(res => dispatch({
      type: GET_REVIEWS,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_REVIEWS,
      payload: {},
    }));
};

export const postProfileFormRequest = contactData => (dispatch) => {
  axios.post('/v2/leadform', contactData)
    .then(res => dispatch({
      type: POST_CONTACT_REQUEST,
      payload: res.data,
    }))
    .catch(err => dispatch({
      type: GET_ERRORS,
      payload: err,
    }));
};

export const postReviewFormRequest = reviewData => (dispatch) => {
  axios.post('/v2/reviews', reviewData)
    .then(res => dispatch({
      type: POST_REVIEW_REQUEST,
      payload: res.data,
    }))
    .catch(err => dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    }));
};

export const getVideos = () => (dispatch) => {
  axios.get('/v2/videos')
    .then(res => dispatch({
      type: GET_VIDEOS,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_VIDEOS,
      payload: {},
    }));
};

export const getNewsletters = () => (dispatch) => {
  axios.get('/v2/newsletters')
    .then(res => dispatch({
      type: GET_NEWSLETTERS,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_NEWSLETTERS,
      payload: {},
    }));
};

export const getSingleNewsletter = newsletterUrl => (dispatch) => {
  axios.get(`/v2/${newsletterUrl}`)
    .then(res => dispatch({
      type: GET_SINGLE_NEWSLETTER,
      payload: res.data.data,
    }),
    )
    .catch(err => dispatch({
      type: GET_SINGLE_NEWSLETTER,
      payload: {},
    }));
};

export const getUnsubscribe = unsubscribeUrl => (dispatch) => {
  dispatch(setLoading());

  axios.get(`/v2${unsubscribeUrl}`)
    .then(res => dispatch({
      type: GET_UNSUBSCRIBE,
      payload: res.data.data,
    }))
    .catch(err => dispatch({
      type: GET_UNSUBSCRIBE,
      payload: null,
    }));
};

export const postUnsubscribeFormRequest = unsubscribeData => (dispatch) => {
  axios.post('/v2/unsubscribe', unsubscribeData)
    .then(() => {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          type: MODAL_TYPE_SUBMIT_SUCCESS,
          props: {},
        },
      });
    })
    .catch(error => dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    }));
};

export const postResubscribeFormRequest = (unsubscribeId, unsubscribeData) => (dispatch) => {
  axios.post(`/v2/leadform/${unsubscribeId}`, unsubscribeData)
    .then(res => dispatch({
      type: POST_RESUBSCRIBE_REQUEST,
      payload: res.data,
    }))
    .catch(err => dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    }));
};

export const feedbackSuccess = feedbackPath => (dispatch) => {
  dispatch(setLoading());
  axios.get(`/v2${feedbackPath}`)
    .then(res => dispatch({
      type: GET_FEEDBACKSUCCESS,
      payload: res,
    }),
    )
    .catch(err => dispatch({
      type: GET_FEEDBACKSUCCESS,
      payload: {},
    }));
};

export const getCustomPage = customPageUrl => (dispatch) => {
  axios.get(`/v2/p/${customPageUrl}`)
    .then(res => dispatch({
      type: GET_CUSTOMPAGE,
      payload: res.data.data,
    }),
    )
    .catch(err => dispatch({
      type: GET_CUSTOMPAGE,
      payload: {},
    }));
};

// Profile Loading
export const setLoading = () => ({
  type: LOADING,
});

//  Clear Errors
export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});
