import React from 'react';
import Parser from 'html-react-parser';
import HeaderOverlay from '../HeaderOverlay';

const HeaderSmall = ({ children, profile }) => {
  const {
    home_hero_image,
    headshot_image,
    user: { vertical },
    info: {
      name_in_header,
      company_in_header,
      full_name,
      company,
      is_use_overlay,
    },
  } = profile;

  let bgStyle;

  if (profile && home_hero_image) {
    bgStyle = { backgroundImage: `url(${home_hero_image})` };
  }

  const showOverlay = profile && home_hero_image && is_use_overlay;

  return (
    <div className={headshot_image ? null : 'header-small'}>
      <input id="v_id" name="v_id" type="hidden" value={vertical} />
      <div className="hero-small" style={bgStyle}>
        <HeaderOverlay
          className="hero-small-overlay"
          show={showOverlay}
        >
          {children}
        </HeaderOverlay>
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            {
              headshot_image
              && (
              <div className="headshot-image mx-auto">
                <img src={headshot_image} alt={full_name && Parser(full_name)} />
              </div>
              )
            }
            {
              company_in_header || name_in_header
                ? (
                  <div className="header-info">
                    <p className="client-name">{name_in_header && full_name && Parser(full_name)} {company_in_header && name_in_header && company && ' - '}
                      {company_in_header && company && Parser(company)}
                    </p>
                  </div>
                ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSmall;
