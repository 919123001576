import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Parser from 'react-html-parser';
import DocumentTitle from 'react-document-title';
import { withRouter } from 'react-router-dom';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import Spinner from '../common/Spinner';
import ReviewItem from './ReviewItem';
import { getReviews } from '../../actions/profileActions';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_REVIEW_FORM } from '../../actions/types';
import { initGA, logPageView } from '../common/Google/ReactGA';
import { loadTitle, toggleProfileColorPalette } from '../../util/helperFuncs';

class Reviews extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { getReviews } = this.props;
    await getReviews();
    initGA();
    logPageView();
    this.openReviewBox();
  }

  showNotification = () => {
    const {
      profile: { profile },
      showModal,
    } = this.props;

    const {
      info: { company },
      headshot_image,
      form_key,
      full_name,
    } = profile;

    showModal(MODAL_TYPE_REVIEW_FORM, {
      headshot: headshot_image,
      fullName: full_name,
      company,
      form_key,
    });
  };

  openReviewBox = () => {
    const { location } = this.props;

    if (!location || !location.search) {
      return;
    }

    const urlSearchParams = new URLSearchParams(location.search);

    const isOpenSearchParam = urlSearchParams.get('is_open');

    if (isOpenSearchParam && isOpenSearchParam === 'true') {
      this.showNotification();
    }
  }

  renderSpinner = () => (
    <div className="reviews">
      <div className="container">
        <div className="row spinner">
          <div className="col">
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      profile: {
        profile,
        reviews,
      },
      loading,
    } = this.props;

    const {
      info: {
        reviews_nav_title_capitalized,
        reviews_title,
        reviews_body,
      },
      page_title,
    } = profile;

    const randomize = id => id + Math.floor(Math.random() * 10000) + id;

    if (profile === null || reviews === null || loading) {
      return this.renderSpinner();
    }

    return (
      <div className="reviews">
        <DocumentTitle title={loadTitle(page_title, 'Reviews', reviews_nav_title_capitalized)}>
          <div>
            <HeaderSmall profile={profile}>
              <Greeting
                title={reviews_title}
                subheading=""
              />
            </HeaderSmall>
            <div className="text-center">
              <h6 className="font-weight-light">{Parser(reviews_body)}</h6>
            </div>
            <main role="main" className="container">
              <div className="row subhead">
                {reviews.reviews.length > 0 && (
                  <div className="container mt-4">
                    <div className="row reviews">
                      {reviews.reviews.map(review => (
                        <ReviewItem
                          key={randomize(review.review_id)}
                          review={review}
                          profile={profile}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div className="col text-center mt-4">
                  <button
                    className="leave-review-button"
                    type="button"
                    onClick={this.showNotification}
                    style={toggleProfileColorPalette(profile, 'leaveReviewButton')}
                  >
                    Leave a review
                  </button>
                </div>
              </div>
            </main>
          </div>
        </DocumentTitle>
      </div>
    );
  }
}

Reviews.defaultProps = {
  loading: false,
};

Reviews.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

const mapDispatchToProps = {
  getReviews,
  showModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews));
