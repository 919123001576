import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { Field, reduxForm } from 'redux-form';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';
import { postProfileFormRequest, getProfile } from '../../actions/profileActions';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../actions/types';
import normalizePhone from '../../util/normalizePhone';
import { loadTitle, toggleProfileColorPalette, isOEDomain } from '../../util/helperFuncs';
import { initGA, logPageView } from '../common/Google/ReactGA';
import ReCaptchaV2 from '../common/Google/ReCaptchaV2';

class ContactRefer extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    formValues: null,
  }

  componentDidMount = () => {
    initGA();
    logPageView();
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  submitForm = () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = (values) => {
      const { profile: { contact, campaign_id } } = this.props.profile;
      const { form_key } = this.state;
      const obj = {
        form_key: '',
        form_name: '',
        lead_trigger_type_id: 11,
        form_data: {},
      };

      const form = document.getElementsByTagName('form');
      const formName = form[0].name;
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.form_data = Object.assign(obj.form_data, values);
      obj.form_name = formName;

      return this.props.postProfileFormRequest(obj);
    };

    onCreateSubmitObj(values);
  }

  setBtnDisabledStateFalse() {
    this.setState({ btnDisabled: false });
  }

  renderPhoneSelectField = (field) => {
    // Select options for phone type
    const phoneOptions = [
      { label: 'Choose one', value: '' },
      { label: 'Home', value: 1 },
      { label: 'Mobile', value: 2 },
      { label: 'Work', value: 3 },
      { label: 'Other', value: 4 },
    ];

    const selectOptions = phoneOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group phone-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">Phone type</label><i className="bar" />
      </div>
    );
  }

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderPhoneField = field => (
    <div className="form-group">
      <input
        {...field.input}
        type={field.type}
        id={field.id}
        className={field.className || ''}
        required={field.required}
      />
      <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
    </div>
  );


  renderTextAreaField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    return (
      <div className="form-group general-inquiry">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderTextAreaFieldReferFriend = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group referrer-message">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
          rows="5"
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label>
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderReferFriendEmailField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group refer-friend-email">
        <input
          {...field.input}
          type="email"
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  render() {
    const { profile, profile: { color_palette }, loading } = this.props.profile;
    const {
      handleSubmit,
      submitting,
      invalid,
      reset,
    } = this.props;
    const { info: { reviews_active }, newsletters, reviews } = profile;

    const { btnDisabled } = this.state;

    const url = profile.user.landing_page_host;
    const isCustomDomain = !isOEDomain(url);

    let contactReferContent;

    const showNotification = () => {
      this.props.showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
    };

    const handleSubmitBtnClick = () => {
      // Get form key from the profile
      this.setState({ form_key: profile.form_key }); // Also set button back to disabled

      // Function to allow simultaneous modal trigger and reading of form field values
      // Sets a buffer timeout to allow values to be read before resetting form fields
      // Manually reset dropdown fields as the reset() only clears all other input fields
      const withBufferTimeout = () => {
        showNotification();
        setTimeout(() => {
          reset();
          this.setState({ btnDisabled: true });
        }, 1000);
      };
      withBufferTimeout();
    };

    if (loading) {
      contactReferContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      contactReferContent = (
        <DocumentTitle title={loadTitle(profile.page_title, 'Contact', profile.info.contact_nav_title_capitalized)}>
          <div className="contact">
            <HeaderSmall profile={profile}>
              <Greeting title="Get in touch" subheading="" profile={profile} />
            </HeaderSmall>
            <div className="container">
              <div className="row">
                <div className="col">
                  <p className="form-context-msg text-center">Please provide your information and we'll get back to you as
                      soon as possible.
                  </p>
                  {/* <!-- Contact Form Start --> */}
                  <div className="row">
                    <div className="col">
                      <div className="required-fields">
                        <small>* = required fields</small>
                      </div>
                      {/* TODO: Fix required on all Field tags as they are all in place to fix a bug that raises the labels by default */}
                      <form id="contact_refer_v2" name="contact_refer_V2" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                        <div className="container contact-form-container">
                          <div className="row">
                            <React.Fragment>
                              <Field
                                type="email"
                                name="referrer_email"
                                id="referrer-email"
                                component={this.renderField}
                                for="referrer_email"
                                className=""
                                label="Email Address*"
                                required
                              />
                            </React.Fragment>
                          </div>
                        </div>
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="referrer_first_name"
                                id="referrer-first-name"
                                component={this.renderField}
                                for="referrer_first_name"
                                className=""
                                label="First Name*"
                                required
                              />
                              <Field
                                type="text"
                                name="referrer_last_name"
                                id="referrer-last-name"
                                component={this.renderField}
                                for="referrer_last_name"
                                className=""
                                label="Last Name"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="tel"
                                name="referrer_phone"
                                id="referrer-phone"
                                component={this.renderPhoneField}
                                for="referrer_phone"
                                className=""
                                label="Phone"
                                normalize={normalizePhone}
                                required
                              />
                              <Field
                                type="select"
                                name="referrer_phone_type"
                                id="referrer-phone-type"
                                component={this.renderPhoneSelectField}
                                for="referrer_phone_type"
                                label={this.selectOptions}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        {/* REFER FRIEND START */}
                        <React.Fragment>
                          <p className="form-subhead text-center mt-5">About your referral</p>
                          <div className="container contact-form-container">
                            <p className="text-center">Most of our business comes through referrals. I would greatly appreciate if you would refer me someone you know that would love our services.</p>
                            <div className="row">
                              <Field
                                type="email"
                                name="email"
                                id="refer-friend-email"
                                component={this.renderReferFriendEmailField}
                                for="email"
                                className="refer-friend-email"
                                label="Email Address*"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="first_name"
                                id="refer-friend-first-name"
                                component={this.renderField}
                                for="first_name"
                                className=""
                                label="First Name*"
                                required
                              />
                              <Field
                                type="text"
                                name="last_name"
                                id="refer-friend-last-name"
                                component={this.renderField}
                                for="last_name"
                                className=""
                                label="Last Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="tel"
                                name="phone"
                                id="refer-friend-phone"
                                component={this.renderPhoneField}
                                for="phone"
                                className=""
                                label="Phone"
                                normalize={normalizePhone}
                                required
                              />
                              <Field
                                type="select"
                                name="phone_type"
                                id="refer-friend-phone-type"
                                component={this.renderPhoneSelectField}
                                for="phone_type"
                                label={this.selectOptions}
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="message"
                                id="referrer-message"
                                component={this.renderTextAreaFieldReferFriend}
                                for="message"
                                className=""
                                label="Add a message"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        {/* REFER FRIEND END */}

                        {!isCustomDomain && (
                          <ReCaptchaV2
                            onVerify={this.submitForm}
                            reCaptchaRef={this.reCaptchaRef}
                          />
                        )}
                        <div className="row">
                          <div className="col text-center submit-btn-wrapper mb-5">
                            <p>By clicking 'Send Message', you agree to be contacted using the contact information you have provided above.</p>
                            <button
                              className="contact-btn btn btn-large"
                              type="submit"
                              name="action"
                              onClick={handleSubmitBtnClick}
                              disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                              style={toggleProfileColorPalette(profile, 'contactButton')}
                            >Send Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <!-- Contact Form End --> */}

                  { newsletters
                    ? <NewslettersCommon profile={profile} colorPalette={color_palette} />
                    : ''
                  }
                  { reviews && reviews_active
                    ? <ReviewsCommon profile={profile} colorPalette={color_palette} />
                    : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <div className="contact">
        {contactReferContent}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.lead_trigger_type_id) {
    errors.lead_trigger_type_id = 'Please choose a valid option';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (values.first_name && (values.first_name.includes('<') || values.first_name.includes('&lt;'))) {
    errors.first_name = 'Invalid text entered';
  }
  if (values.last_name && (values.last_name.includes('<') || values.last_name.includes('&lt;'))) {
    errors.last_name = 'Invalid text entered';
  }
  if (values.message && (values.message.includes('<') || values.message.includes('&lt;'))) {
    errors.message = 'Invalid text entered';
  }
  if (!values.referrer_first_name) {
    errors.referrer_first_name = 'First name is required';
  }
  if (values.referrer_first_name && (values.referrer_first_name.includes('<') || values.referrer_first_name.includes('&lt;'))) {
    errors.referrer_first_name = 'Invalid text entered';
  }
  if (values.referrer_last_name && (values.referrer_last_name.includes('<') || values.referrer_last_name.includes('&lt;'))) {
    errors.referrer_last_name = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  if (!values.referrer_email) {
    errors.referrer_email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.referrer_email)) {
    errors.referrer_email = 'Valid email required.';
  }

  return errors;
};

ContactRefer.defaultProps = {
  loading: false,
};

ContactRefer.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default compose(
  connect(mapStateToProps, { getProfile, postProfileFormRequest, showModal }),
  reduxForm({ validate, form: 'ContactReferForm' }),
)(ContactRefer);
