import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Parser from 'react-html-parser';
import { postProfileFormRequest } from '../../../../actions/profileActions';
import { showModal } from '../../../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../../../actions/types';
import { toggleProfileColorPalette, isOEDomain } from '../../../../util/helperFuncs';
import ReCaptchaV2 from '../../Google/ReCaptchaV2';

class FooterSubscribe extends Component {
    state = {
      btnDisabled: false,
      form_key: '',
      errors: {},
      formValues: null,
    }

    reCaptchaRef = React.createRef();

    validateUser = () => {
      const url = this.props.profile.profile.user.landing_page_host;

      if (!isOEDomain(url)) {
        this.submitForm();
      } else if (this.reCaptchaRef.current) {
        this.reCaptchaRef.current.execute();
      }
    }

    onSubmit = (formValues) => {
      this.setState(
        { formValues },
        this.validateUser,
      );
    }

    submitForm = () => {
      const { formValues: values } = this.state;

      const onCreateSubmitObj = async (values) => {
        const { profile: { contact, campaign_id } } = this.props.profile;
        const { postProfileFormRequest, showModal } = this.props;
        const { form_key } = this.state;
        const obj = {
          form_key: '',
          form_name: '',
          lead_trigger_type_id: null,
          form_data: {},
        };

        const form = document.getElementsByTagName('form');
        const formName = form[0].name;
        obj.form_key = form_key;
        // Add contact and campaign IDs if they exist via cookie from server
        if (contact) {
          obj.contact_id = Number(contact.id);
        }
        if (campaign_id) {
          obj.campaign_id = Number(campaign_id);
        }
        obj.lead_trigger_type_id = 6;
        delete values.lead_trigger_type_id; // Remove lead_trigger_type_id from payload
        obj.form_data = Object.assign(obj.form_data, values);
        obj.form_name = formName;

        await postProfileFormRequest(obj);

        showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
      };

      return onCreateSubmitObj(values);
    }

    renderField = (field) => {
      const { meta: { touched, error } } = field;
      const textHelp = `error ${touched && error ? 'danger' : ''} footer-sub-error`;
      return (
        <div className="footer-sub mt-1">
          <label htmlFor={field.for}>{field.label}</label>
          <input
            {...field.input}
            type={field.type}
            id={field.id}
            className={field.className || ''}
            required={field.required}
            placeholder={field.placeholder}
          />
          <p className={textHelp}>{ touched && error ? error : '' }</p>
        </div>
      );
    }

    renderHoney = (field) => {
      return (
        <div className="footer-sub mt-1">
          <input
              type="text"
              name="email-verify-oek-740"
              id="email-verify-oek-740"
              className="honey"
              value=""
          />
        </div>
      );
    }


    render() {
      const {
        profile: { profile },
        handleSubmit,
        submitting,
        invalid,
        reset,
      } = this.props;

      const { btnDisabled } = this.state;
      const { info: { footer_subscribe_prompt } } = profile;

      const url = profile.user.landing_page_host;
      const isCustomDomain = !isOEDomain(url);

      const handleSubmitBtnClick = () => {
        this.setState({ form_key: profile.form_key });

        const withBufferTimeout = () => {
          setTimeout(() => {
            reset();
            this.setState({ btnDisabled: true });
          }, 1000);
        };

        withBufferTimeout();
      };

      return (
        <React.Fragment>
          <form id="FooterSubscribeForm" name="FooterSubscribeForm" onSubmit={handleSubmit(this.onSubmit)} className="md-form footer-sub-form" noValidate>
            <Field
              type="text"
              name="email-verify-oek-740"
              id="email-verify-oek-740"
              component={this.renderHoney}
              for="email-verify-oek-740"
              className="form-control"
            />
            <Field
              type="email"
              name="email"
              id="email"
              component={this.renderField}
              for="email"
              className="form-control"
              // label="Don't miss our updates and latest news"
              label={footer_subscribe_prompt && Parser(footer_subscribe_prompt.slice(0, 40))}
              placeholder="your@email-here.com"
              required
            />
            {!isCustomDomain && (
              <ReCaptchaV2
                onVerify={this.submitForm}
                reCaptchaRef={this.reCaptchaRef}
              />
            )}
            <div className="text-center footer-sub-btn mt-3">
              <button
                className="contact-btn btn btn-large mt-1"
                type="submit"
                name="action"
                onClick={handleSubmitBtnClick}
                disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                style={toggleProfileColorPalette(profile, 'footerSubscribeButton')}
              >
                Subscribe
              </button>
            </div>
          </form>
        </React.Fragment>
      );
    }
}

const validate = (values) => {
  const errors = {};
  if (!values.lead_trigger_type_id) {
    errors.lead_trigger_type_id = 'Please choose a valid option';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }

  return errors;
};

FooterSubscribe.defaultProps = {
  loading: false,
};

FooterSubscribe.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default compose(
  connect(mapStateToProps, { postProfileFormRequest, showModal }),
  reduxForm({ validate, form: 'FooterSubscribe' }),
)(FooterSubscribe);
