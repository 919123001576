import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { showModal } from '../../../actions/modalActions';
import { MODAL_TYPE_CONTACT_GENERAL_FORM } from '../../../actions/types';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

const CTA = (props) => {
  const { profile } = props;
  const showNotification = () => {
    props.showModal(MODAL_TYPE_CONTACT_GENERAL_FORM, {
      headshot: profile.headshot_image,
      fullName: profile.full_name,
      company: profile.info.company,
      form_key: profile.form_key,
    });
  };

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col contact-cta text-center">
          <Link
            to="/refer-a-friend"
            role="button"
            className="cta-contact-link text-center"
            style={togglePropsColorPalette(props, 'cta')}
          ><i
            className="fas fa-comments mr-2"
          />
            { profile.user.vertical === 25
            || profile.user.vertical === 31
            || profile.user.vertical === 34
            || profile.user.vertical === 35
            || profile.user.vertical === 20 ? 'Refer an Agent' : 'Refer a Friend'}
          </Link>
          <p className="pagelink">
            <span className="divider" style={togglePropsColorPalette(props, 'divider')} />
          </p>
          <button onClick={showNotification} type="button" className="cta-contact-link text-center" style={togglePropsColorPalette(props, 'cta')}><i
            className="fas fa-envelope mr-2"
          />Get in touch
          </button>
        </div>
      </div>
    </div>
  );
};

CTA.propTypes = {
  profile: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(null, { showModal })(CTA);
