import React from 'react';
import DocumentTitle from 'react-document-title';
import Parser from 'react-html-parser';
import { loadTitle } from '../../util/helperFuncs';

const uuidv4 = require('uuid/v4');

const SuccessPage = (props) => {
  const { profile } = props;
  const {
    info: {
      company_url,
      blog_url,
      linkedin_url,
      facebook_url,
      plus_url,
      twitter_handle,
      pinterest_url,
      yelp_url,
      foursquare_url,
      instagram_url,
      zillow_url,
      youtube_url,
      avvo_url,
      lawyers_url,
      realtor_url,
      company,
      address1,
      address2,
      city,
      state,
      zip,
    }, headshot_image, phone_numbers, page_title, full_name,
  } = profile;

  const phoneInfo = phone_numbers.map(item => <div key={uuidv4()} className="success-link phone"><span className="font-weight-normal">{item.label}: </span><a href={`tel: ${item.number}`}>{item.number}</a></div>);

  return (
    <DocumentTitle title={loadTitle(page_title, 'Thank You!')}>
      <div className="container">
        <div className="row success">
          <div className="col text-center">
            <h2 className="mb-5">Thank you for your feedback. It is appreciated.</h2>
            <div className="text-center feedback-success-img">
              <div className="feedback-headshot-image mx-auto">
                { headshot_image && <img src={headshot_image} alt="" /> }
              </div>
            </div>
          </div>
        </div>
        <div className="row social-row mb-3">
          <div className="col text-center">
            { company_url ? <span className="pl-1 pr-1 social-link"><a href={company_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-globe company-globe-blog" /></a></span> : '' }
            { blog_url ? <span className="pl-1 pr-1 social-link"><a href={blog_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-pencil-circle company-globe-blog" /></a></span> : '' }
            { linkedin_url ? <span className="pl-1 pr-1 social-link"><a href={linkedin_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-linkedin-circle linkedin-icon" /></a></span> : '' }
            { facebook_url ? <span className="pl-1 pr-1 social-link"><a href={facebook_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-facebook-circle facebook-icon" /></a></span> : '' }
            { plus_url ? <span className="pl-1 pr-1 social-link"><a href={plus_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-googleplus-circle googleplus-icon" /></a></span> : '' }
            { twitter_handle ? <span className="pl-1 pr-1 social-link"><a href={twitter_handle} target="_blank" rel="noopener noreferrer"><i className="oe-icon-twitter-circle twitter-icon" /></a></span> : '' }
            { pinterest_url ? <span className="pl-1 pr-1 social-link"><a href={pinterest_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-pinterest-circle pinterest-icon" /></a></span> : '' }
            { yelp_url ? <span className="pl-1 pr-1 social-link"><a href={yelp_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-yelp-circle yelp-icon" /></a></span> : '' }
            { foursquare_url ? <span className="pl-1 pr-1 social-link"><a href={foursquare_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-foursquare-circle foursquare-icon" /></a></span> : '' }
            { instagram_url ? <span className="pl-1 pr-1 social-link"><a href={instagram_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-instagram-circle instagram-icon" /></a></span> : '' }
            { zillow_url ? <span className="pl-1 pr-1 social-link"><a href={zillow_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-zillow-circle zillow-icon" /></a></span> : '' }
            { youtube_url ? <span className="pl-1 pr-1 social-link"><a href={youtube_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-youtube youtube-icon" /></a></span> : '' }
            { avvo_url ? <span className="pl-1 pr-1 social-link"><a href={avvo_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-avvo avvo-icon" /></a></span> : '' }
            { lawyers_url ? <span className="pl-1 pr-1 social-link"><a href={lawyers_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-lawyers-circle lawyerscircle-icon" /></a></span> : '' }
            { realtor_url ? <span className="pl-1 pr-1 social-link"><a href={realtor_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-realtor-circle realtorscircle-icon" /></a></span> : '' }
          </div>
        </div>
        <div className="row mb-2">
          <div className="col success-links-component text-center">
            <p className="pagelink text-center">{full_name && Parser(full_name)}</p>
            <p className="pagelink text-center">|</p>
            <p className="pagelink text-center">{company && Parser(company)}</p>
            <p className="pagelink text-center">|</p>
            <p className="pagelink text-center">{address1 && Parser(address1)} {address2 && Parser(address2)}</p>
            <p className="pagelink text-center">|</p>
            <p className="pagelink text-center">{`${city && Parser(city)}, ${state && Parser(state)}  ${zip && Parser(zip)}`}</p>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            {phoneInfo}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default SuccessPage;
