import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { getNewsletters } from '../../actions/profileActions';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import CTA from '../common/CTA/CTA';
import NewslettersItem from './NewslettersItem';
import Spinner from '../common/Spinner';
import { showModal } from '../../actions/modalActions';
import { initGA, logPageView } from '../common/Google/ReactGA';
import { loadTitle, toggleProfileColorPalette } from '../../util/helperFuncs';
import { MODAL_TYPE_SUBSCRIBE_FORM } from '../../actions/types';


class Newsletters extends Component {
  constructor(props) {
    super(props);
    this.state = { allNewsletters: false };
    this.showAllNewsletters = this.showAllNewsletters.bind(this);
  }

  componentDidMount = async () => {
    const { getNewsletters } = this.props;
    window.scrollTo(0, 0);
    await getNewsletters();
    initGA();
    logPageView();
  }

  setNewslettersObj(newsletters) {
    const { allNewsletters } = this.state;
    if (allNewsletters) {
      return newsletters;
    }
    return newsletters.slice(0, 6);
  }

  showAllNewsletters() {
    this.setState({ allNewsletters: true });
  }

  render() {
    const { profile, newsletters, loading } = this.props.profile;
    const { page_title, info: { newsletters_nav_title_capitalized, newsletter_page_title } } = profile;
    const { allNewsletters } = this.state;
    const showNotification = () => {
      this.props.showModal(MODAL_TYPE_SUBSCRIBE_FORM, {
        headshot: profile.headshot_image,
        fullName: profile.full_name,
        company: profile.info.company,
        form_key: profile.form_key,
      });
    };
    let newslettersPageContent;

    if (profile === null || newsletters === null || loading) {
      newslettersPageContent = (
        <div className="container spinner">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      const displayNewsletters = this.setNewslettersObj(newsletters.newsletters);
      const { color_palette } = profile;

      newslettersPageContent = (
        <DocumentTitle title={loadTitle(page_title, 'Newsletters', newsletters_nav_title_capitalized)}>
          <div>
            <HeaderSmall profile={profile}>
              <Greeting title={newsletter_page_title} subheading="" />
            </HeaderSmall>
            <CTA profile={profile} colorPalette={color_palette} />
            {
            newsletters.newsletters
              ? (
                <div className="container">
                  <div className="row newsletters">
                    {displayNewsletters.map(newsletter => (
                      <NewslettersItem
                        key={newsletter.campaign.id}
                        campaignId={newsletter.campaign.id}
                        imageUrl={newsletter.image_url}
                        campaignName={newsletter.campaign.name}
                        shareDescription={newsletter.campaign.share_description}
                        campaignDate={newsletter.campaign_date}
                        onlineUrl={newsletter.campaign.newsletter_online_url}
                        linkColor={toggleProfileColorPalette(profile, 'link')}
                      />
                    ))}
                  </div>
                </div>
              ) : ''
          }
            <div className="container mt-5">
              <div className="row subhead">
                <div className="col header-links-component newsletters text-center">
                  <div className="pagelink text-center" style={toggleProfileColorPalette(profile, 'link')} onClick={showNotification} role="button">Subscribe to Newsletter</div>
                  {
                    (allNewsletters && displayNewsletters.length > 6) ? null
                      : (
                        <React.Fragment>
                          <p className="pagelink"><span className="divider" style={toggleProfileColorPalette(profile, 'divider')} /></p>
                          <div className="pagelink text-center" onClick={this.showAllNewsletters} role="button" tabIndex={0} style={toggleProfileColorPalette(profile, 'link')}>Show More</div>
                        </React.Fragment>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </DocumentTitle>
      );
    }
    return (
      <div className="newsletters">
        {newslettersPageContent}
      </div>
    );
  }
}

Newsletters.defaultProps = {
  loading: false,
  newsletters: {},
};

Newsletters.propTypes = {
  profile: PropTypes.object.isRequired,
  newsletters: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  profile: state.profile,
  newsletters: state.profile.newsletters,
});

export default connect(mapStateToProps, { showModal, getNewsletters })(Newsletters);
