import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Parser from 'html-react-parser';
import { getHome } from '../../../../actions/profileActions';

class GoogleMap extends Component {
  componentDidMount() {
    this.renderMap();
  }

  renderMap = () => {
    const { home } = this.props;
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${home.google_maps_api_key}&callback=initMap`);
    window.initMap = this.initMap;
  }

  initMap = () => this.geocode()


  buildAddress = () => {
    const { profile } = this.props.profile;
    const address = `${profile.info.address1} ${profile.info.city}, ${profile.info.state}`;
    return address;
  }

  getCompany = () => {
    const {
      company, address1, city, state, zip,
    } = this.props.profile.profile.info;
    const companyFormatted = `<div className="text-center google-map">
      <p className="google-map-content"><strong>${company}</strong></p>
      <span className="google-map-content">${address1}</span><br/>
      <span className="google-map-content">${city}, ${state} ${zip}</span>
    </div>`;
    return companyFormatted;
  }

  getCompanyName = () => {
    const { company } = this.props.profile.profile.info;
    return company && Parser(company);
  }

  geocode = () => {
    const { home } = this.props;
    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: this.buildAddress(),
        key: home.google_maps_api_key,
      },
    })
      .then((response) => {
        // const formattedAddress = response.data.results[0].formatted_address;
        const geometry = response.data.results[0].geometry.location;

        const infowindow = new window.google.maps.InfoWindow({
          content: this.getCompany(),
        });
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: geometry.lat, lng: geometry.lng },
          zoom: 8,
        });

        const marker = new window.google.maps.Marker({
          position: { lat: geometry.lat, lng: geometry.lng },
          map,
          title: this.getCompanyName(),
        });

        marker.addListener('click', () => {
          infowindow.open(map, marker);
        });
        return geometry;
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }

  render() {
    return (
      <div id="map" />
    );
  }
}

function loadScript(url) {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

const mapStateToProps = state => ({
  profile: state.profile,
  home: state.profile.home,
});

export default connect(mapStateToProps, { getHome })(GoogleMap);
