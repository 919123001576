import React from 'react';
import { Link } from 'react-router-dom';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

const ContactCompanyLogo = (props) => {
  const { logoImage } = props;
  return (
    <div className="row subhead">
      <div className="col text-center mt-5 home-contact-btn">
        <Link className="mx-auto contact-me-btn" to="/contact" style={togglePropsColorPalette(props, 'contactButton')}>Get in touch today</Link>
        {logoImage && <img className="logo" src={logoImage} alt="" />}
      </div>
    </div>
  );
};

export default ContactCompanyLogo;
