import React from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { getProfile, getHome } from '../../actions/profileActions';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import HeaderLarge from '../common/layout/HeaderLarge';
import ContactCompanyLogo from '../common/ContactCompanyLogo/ContactCompanyLogo';
import Spinner from '../common/Spinner';
import GoogleMap from '../common/Google/GoogleMap/GoogleMap';
import { loadTitle } from '../../util/helperFuncs';

const Profile = (props) => {
  const { profile: { profile, home }, loading } = props;
  const { getHome } = props;
  if (!home) getHome();
  const {
    info: {
      home_nav_title_capitalized,
      about_us_active,
      about_us_title,
      about_us_body,
      reviews_active,
    }, page_title, logo_image, newsletters, reviews, color_palette,
  } = profile;

  let profileContent;

  if (profile === null || loading) {
    profileContent = (
      <div className="container">
        <div className="row">
          <div className="col">
            <Spinner />
          </div>
        </div>
      </div>
    );
  } else {
    window.scrollTo(0, 0);
    profileContent = (
      <DocumentTitle title={loadTitle(page_title, 'Home', home_nav_title_capitalized)}>
        <div>
          <HeaderLarge profile={profile} />
          <main role="main" className="container">
            <ContactCompanyLogo logoImage={logo_image} colorPalette={color_palette} />
            {/* <!-- Bio Start --> */}
            { about_us_active
            && (
              <div className="row subhead">
                <div className="col bio text-center">
                  { about_us_title && about_us_body && (
                    <h2>{ Parser(about_us_title)}</h2>
                  )}
                  { about_us_body && (<div className="mx-auto">{Parser(about_us_body)}</div>)}
                  { home && home.about_us_image && (
                  <div className="mx-auto about-img">
                    <img src={home.about_us_image} alt="About" />
                  </div>
                  )}
                </div>
              </div>
            )
          }
            {/* <!-- Bio End --> */}

            { newsletters
              ? <NewslettersCommon profile={profile} colorPalette={color_palette} />
              : ''
          }
            { reviews && reviews_active
              ? <ReviewsCommon profile={profile} colorPalette={color_palette} />
              : ''
          }

          </main>
          {/* <!-- Google Map Start --> */}
          { profile.info.is_show_map && home && (
          <div className="row">
            <div className="col">
              <GoogleMap home={home} />
            </div>
          </div>
          )
          }
          {/* <!-- Google Map End --> */}
        </div>
      </DocumentTitle>
      /* <!-- Main Content End --> */
    );
  }

  return (
    <div className="profile">
      {profileContent}
    </div>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  home: state.home,
});

export default connect(mapStateToProps, { getProfile, getHome })(Profile);
