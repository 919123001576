// List all action types to be used by action files
export const GET_PROFILE = 'GET_PROFILE';
export const GET_HOME = 'GET_HOME';
export const GET_REVIEWS = 'GET_REVIEWS';
export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_REVIEW_REQUEST = 'POST_REVIEW_REQUEST';
export const GET_SINGLE_NEWSLETTER = 'GET_SINGLE_NEWSLETTER';
export const GET_NEWSLETTERS = 'GET_NEWSLETTERS';
export const GET_VIDEOS = 'GET_VIDEOS';
export const LOADING = 'LOADING';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const MODAL_TYPE_REVIEW_ITEM = 'MODAL_TYPE_REVIEW_ITEM';
export const MODAL_TYPE_REVIEW_FORM = 'MODAL_TYPE_REVIEW_FORM';
export const MODAL_TYPE_CONTACT_GENERAL_FORM = 'MODAL_TYPE_CONTACT_GENERAL_FORM';
export const MODAL_TYPE_SUBSCRIBE_FORM = 'MODAL_TYPE_SUBSCRIBE_FORM';
export const MODAL_TYPE_SUBMIT_SUCCESS = 'MODAL_TYPE_SUBMIT_SUCCESS';
export const GET_UNSUBSCRIBE = 'GET_UNSUBSCRIBE';
export const POST_RESUBSCRIBE_REQUEST = 'POST_RESUBSCRIBE_REQUEST';
export const GET_FEEDBACKSUCCESS = 'GET_FEEDBACKSUCCESS';
export const GET_CUSTOMPAGE = 'GET_CUSTOMPAGE';
export const GET_RECAPTCHA_V3_SITE_KEY = 'GET_RECAPTCHA_V3_SITE_KEY';
export const GET_RECAPTCHA_V2_SITE_KEY = 'GET_RECAPTCHA_V2_SITE_KEY';
export const UNSUBSCRIBED = 'UNSUBSCRIBED';
