import React from 'react';

class Message extends React.Component {
  render() {
    return (
      <p className="ml-3 mt-4">{`${this.props.user}: `}{this.props.message}</p>
    );
  }
}

export default Message;
