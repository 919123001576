import React from 'react';

const ImageRight = props => (
  <div className="row custom-layout">
    <div className="col-sm-6 custom-left">{props.text}</div>
    <div className="col-sm-6 custom-right"><img src={props.image} alt="custom page" /></div>
  </div>
);

export default ImageRight;
