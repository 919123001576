import React from 'react';
import Parser from 'html-react-parser';
import HeaderOverlay from '../HeaderOverlay';

const HeaderLarge = ({ profile }) => {
  const {
    home_hero_image,
    headshot_image,
    user: { vertical },
    info: {
      home_heading,
      home_subheading,
      name_in_header,
      company_in_header,
      full_name,
      company,
      is_use_overlay,
    },
  } = profile;

  // Dynamic background image  and darken overlay toggle
  let bgStyle;

  if (profile && home_hero_image) {
    bgStyle = { backgroundImage: `url(${home_hero_image})` };
  }

  const headerInner = (
    <div className={headshot_image ? 'col text-center greeting' : 'col text-center greeting no-headshot'}>
      {home_heading
        && <h1 className="">{Parser(home_heading)}</h1>}
      {home_subheading
        && <p>{Parser(home_subheading)}</p>
        }
    </div>
  );

  const showOverlay = profile && home_hero_image && is_use_overlay;

  return (
    <React.Fragment>
      <input id="v_id" name="v_id" type="hidden" value={vertical} />
      <div>
        <div className="hero-large" style={bgStyle}>
          <HeaderOverlay
            className="hero-large-overlay"
            show={showOverlay}
          >
            {headerInner}
          </HeaderOverlay>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-center header-large">
            {
                headshot_image
                && (
                <div className="headshot-image mx-auto">
                  <img src={headshot_image} alt="" />
                </div>
                )
            }
            {
                name_in_header || company_in_header
                  ? (
                    <div className="header-info">
                      { name_in_header && full_name && <p className="client-name">{Parser(full_name)}</p> }
                      { company_in_header && company && <p className="client-company">{Parser(company)}</p> }
                    </div>
                  ) : null
              }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderLarge;
