import React from 'react';

function NotSupported() {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center not-supported-heading">
          <h1 className="font-weight-light">Thanks for visiting our site.</h1>
          <h6>Unfortunately, we don't support your browser at this time.</h6>
          <div className="not-supported-image">
            <img src="https://s3.amazonaws.com/outboundengine/images/illustration.png" alt="" />
          </div>
          <p>To enjoy this site, we recommend using <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, or <a href="https://support.apple.com/downloads/safari">Safari</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default NotSupported;
