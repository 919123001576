import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

export const initGA = () => {
  ReactGA.initialize('UA-45986038-2');
};

export const logPageView = () => {
  const history = createBrowserHistory();
  const { location } = history;
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};
