// Create Vertical Array for dynamic select dropdown rendering
export const verticalList = [
  {
    verticalName: 'REAL_ESTATE',
    verticalId: 1,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a home estimate', value: 16, ctaText: 'Get Estimate', showFlag: 'page1_active', path: '/home-report',
      },
      {
        label: 'I would like to view your home listings', value: 20, ctaText: 'Request Listings', showFlag: 'page2_active', path: '/home-listings',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'HEARST_REAL_ESTATE',
    verticalId: 18,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a home estimate', value: 16, ctaText: 'Get Estimate', showFlag: 'page1_active', path: '/home-report',
      },
      {
        label: 'I would like to view your home listings', value: 20, ctaText: 'Request Listings', showFlag: 'page2_active', path: '/home-listings',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'REAL_ESTATE_CITY_LIVING',
    verticalId: 27,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a home estimate', value: 16, ctaText: 'Get Estimate', showFlag: 'page1_active', path: '/home-report',
      },
      {
        label: 'I would like to view your home listings', value: 20, ctaText: 'Request Listings', showFlag: 'page2_active', path: '/home-listings',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'REAL_ESTATE_LUXURY',
    verticalId: 29,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a home estimate', value: 16, ctaText: 'Get Estimate', showFlag: 'page1_active', path: '/home-report',
      },
      {
        label: 'I would like to view your home listings', value: 20, ctaText: 'Request Listings', showFlag: 'page2_active', path: '/home-listings',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'MORTGAGE_BROKER',
    verticalId: 4,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a consultation', value: 14, ctaText: 'Request Consultation', showFlag: 'page1_active', path: '/consultation',
      },
      {
        label: 'I would like to calculate a mortgage', value: 13, ctaText: 'Request Calculation', showFlag: 'page2_active', path: '/mortgage-calculator',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'MORTGAGE_BLUELEAF',
    verticalId: 19,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a consultation', value: 14, ctaText: 'Request Consultation', showFlag: 'page1_active', path: '/consultation',
      },
      {
        label: 'I would like to calculate a mortgage', value: 13, ctaText: 'Request Calculation', showFlag: 'page2_active', path: '/mortgage-calculator',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'PROMO',
    verticalId: 6,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a quote', value: 17, ctaText: 'Get Quote', showFlag: 'page1_active', path: '/request-a-quote',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'INSURANCE',
    verticalId: 2,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a quote', value: 9, ctaText: 'Get a Quote', showFlag: 'page1_active', path: '/quote',
      },
      {
        label: 'I would like someone to review my policy', value: 12, ctaText: 'Get My Policy Review', showFlag: 'page2_active', path: '/policy-review',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'LIFE_HEALTH',
    verticalId: 24,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a quote', value: 9, ctaText: 'Get a Quote', showFlag: 'page1_active', path: '/quote',
      },
      {
        label: 'I would like someone to review my policy', value: 12, ctaText: 'Get My Policy Review', showFlag: 'page2_active', path: '/policy-review',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'TWFG_INSURANCE',
    verticalId: 33,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a quote', value: 9, ctaText: 'Get a Quote', showFlag: 'page1_active', path: '/quote',
      },
      {
        label: 'I would like someone to review my policy', value: 12, ctaText: 'Get My Policy Review', showFlag: 'page2_active', path: '/policy-review',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'FINANCIAL_ADVISORS',
    verticalId: 22,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to schedule a meeting', value: 23, ctaText: 'Request Meeting', showFlag: 'page1_active', path: '/schedule-an-advisory-meeting',
      },
      {
        label: 'I have a question', value: 25, ctaText: 'Submit', showFlag: 'page2_active', path: '/send-me-a-question',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'REAL_ESTATE_RECRUITING',
    verticalId: 25,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/apply',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-an-agent',
      },
      // Recruiting verticals have "Refer an Agent" instead of "Refer a Friend" and it comes in as page1_active instead of refer_active
    ],
  },
  {
    verticalName: 'REAL_ESTATE_RECRUITING_2',
    verticalId: 31,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/apply',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-an-agent',
      },
      // Recruiting verticals have "Refer an Agent" instead of "Refer a Friend" and it comes in as page1_active instead of refer_active
    ],
  },
  {
    verticalName: 'MORTGAGE_RECRUITING',
    verticalId: 26,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/careers',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'EQUITY_PRIME_MORTGAGE',
    verticalId: 32,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/careers',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'TWFG_INSURANCE_RECRUITING',
    verticalId: 35,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/careers',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-an-agent',
      },
    ],
  },
  {
    verticalName: 'KELLER_WILLIAMS_RECRUITING',
    verticalId: 20,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/apply',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-an-agent',
      },
    ],
  },
  {
    verticalName: 'WEICHERT',
    verticalId: 34,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to join your team', value: 10, ctaText: 'Submit Inquiry', showFlag: 'page2_active', path: '/apply',
      },
      {
        label: 'I would like to refer someone', value: 15, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-an-agent',
      },
    ],
  },
  {
    verticalName: 'HOME_SERVICES',
    verticalId: 11,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like an estimate', value: 16, ctaText: 'Request Estimate', showFlag: 'page1_active', path: '/estimate',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page2_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'WELLNESS',
    verticalId: 12,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page2_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'MARKETING_AGENCY',
    verticalId: 28,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to request a quote', value: 9, ctaText: 'Submit', showFlag: 'page1_active', path: '/quote',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'AUTOMOTIVE',
    verticalId: 7,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like an estimate', value: 16, ctaText: 'Submit', showFlag: 'page1_active', path: '/estimate',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page2_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'LEGAL_CRIMINAL_DEFENSE',
    verticalId: 21,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a consultation', value: 18, ctaText: 'Request Consultation', showFlag: 'page1_active', path: '/legal-consultation',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'BEAUTY',
    verticalId: 8,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page1_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'EVENTS',
    verticalId: 14,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a quote', value: 9, ctaText: 'Get a Quote', showFlag: 'page1_active', path: '/quote',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'TRAVEL',
    verticalId: 36,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like a consultation', value: 14, ctaText: 'Request Consultation', showFlag: 'page1_active', path: '/consultation',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'PETS',
    verticalId: 37,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page1_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
  {
    verticalName: 'FOOD',
    verticalId: 38,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
    ],
  },
  {
    verticalName: 'NO_CONTENT_AVAILABLE',
    verticalId: 39,
    options: [
      { label: 'Select one', value: '', ctaText: 'Submit' },
      {
        label: 'General Question', value: 19, ctaText: 'Submit', showFlag: 'contact_active',
      },
      {
        label: 'I would like to schedule an appointment', value: 22, ctaText: 'Submit', showFlag: 'page1_active', path: '/appointment',
      },
      {
        label: 'I would like to refer someone', value: 11, ctaText: 'Submit', showFlag: 'refer_active', path: '/refer-a-friend',
      },
    ],
  },
];

export const campaignPaths = ['/home-report', '/home-listings', '/consultation', '/mortgage-calculator', '/request-a-quote', '/quote',
  '/policy-review', '/schedule-an-advisory-meeting', '/send-me-a-question', '/refer-a-friend', '/refer-an-agent', '/apply', '/estimate', '/appointment',
  '/careers', '/legal-consultation', '/refer', '/kwrecruiting'];
