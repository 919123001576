import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import modalReducer from './modalReducer';
import secretsReducer from './SecretsReducer';

// Add reducers to the combineReducers function as an object
// States of each chiled reducer are namespaced by the function so that they
// can be combined into a single state object
export default combineReducers({
  errors: errorReducer,
  profile: profileReducer,
  modal: modalReducer,
  form: formReducer,
  secrets: secretsReducer,
});
