import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Parser from 'react-html-parser';
import Disclaimer from './Disclaimer';
import CustomLink from '../../CustomLinks/CustomLink';
import CustomPageLink from '../../CustomLinks/CustomPageLink';
import FooterSubscribe from './FooterSubscribe';
import { toggleProfileColorPalette } from '../../../../util/helperFuncs';
import '../../../../assets/oe-icons/icons.css';

const uuidv4 = require('uuid/v4');

function parseSafe(text) {
  if (text === undefined || text === null) {
    return '';
  }

  const parsedText = Parser(text);

  if (parsedText === undefined || parsedText === null) {
    return '';
  }

  return parsedText;
}

const renderContactName = (fullName, brokerage) => (
  `${parseSafe(fullName)}${brokerage ? ' | ' : ''}${parseSafe(brokerage)}`
);

const renderLocality = (city, state, zip, country) => (
  `${parseSafe(city)}${city && state && ', '}${parseSafe(state)} ${parseSafe(zip)} ${parseSafe(country)}`
);

// OEK-170, OEK-244
const isARealEstateVerticalForNewYork = verticalId => [1, 27, 29].includes(verticalId);
const isNewYorkState = state => state === 'NY';

const Footer = ({ profile }) => {
  if (!profile) return null;

  const {
    custom_header_links,
    phone_numbers,
    info,
    certification_image,
    show_reviews,
    color_palette,
    full_name,
    user,
  } = profile;

  const {
    company,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    company_url,
    linkedin_url,
    blog_url,
    facebook_url,
    plus_url,
    twitter_handle,
    pinterest_url,
    yelp_url,
    foursquare_url,
    instagram_url,
    zillow_url,
    youtube_url,
    avvo_url,
    lawyers_url,
    realtor_url,
    contact_nav_title,
    custom_page_navbar_title,
    custom_page_enabled,
    disclaimer,
    reviews_nav_title,
    custom_link1_link,
    custom_link2_link,
    job_title,
    extra_info,
    email,
    video_page_active,
    video_page_nav_title,
    newsletters_active,
    newsletters_nav_title,
    brokerage,
  } = info;

  const { vertical } = user;

  const phoneInfo = phone_numbers.map(item => <div key={uuidv4()} className="address-item phone">{item.label} - <a href={`tel: ${item.number}`}>{parseSafe(item.number)}</a></div>);

  const parsedDisclaimerText = parseSafe(disclaimer);

  const renderCustomLink = (path, label) => (
    <li>
      <CustomLink
        path={path}
        label={label}
      />
    </li>
  );

  const renderCustomLink1 = () => {
    const label = isARealEstateVerticalForNewYork(vertical) && isNewYorkState(state)
      ? 'State of NY SOP for Brokerages'
      // Label for TX as default
      : 'TREC Consumer Protection Notice';

    if (custom_link1_link) {
      return renderCustomLink(custom_link1_link, label);
    }

    return null;
  };

  const renderCustomLink2 = () => {
    const label = isARealEstateVerticalForNewYork(vertical) && isNewYorkState(state)
      ? 'NY Fair Housing Notice'
      // Label for TX as default
      : 'TREC Information About Brokerage Services';

    if (custom_link2_link) {
      return renderCustomLink(custom_link2_link, label);
    }

    return null;
  };

  return (
    <div className="footer" style={toggleProfileColorPalette(profile, 'footer')}>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-4">
            <div className="vcard">
              <div className="address">
                <div className="address-item contact-name">{renderContactName(full_name, brokerage)}</div>
                <div className="address-item job-title">{parseSafe(job_title)}</div>
                <div className="address-item company-name">{parseSafe(company)}</div>
                <div className="address-item extra-info">{parseSafe(extra_info)}</div>
                <div className="address-item street">{parseSafe(address1)}</div>
                <div className="address-item suite-num">{parseSafe(address2)}</div>
                <div className="address-item locality">{renderLocality(city, state, zip, country)}</div>
                {phoneInfo}
                <div className="address-item email"><a href={`mailto:${email}`}>{email}</a></div>
                <div className="address-item icons mt-3">
                  <div className="container">
                    <div className="row">
                      { company_url ? <span><a href={company_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-globe" /></a></span> : '' }
                      { blog_url ? <span><a href={blog_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-pencil-circle" /></a></span> : '' }
                      { linkedin_url ? <span><a href={linkedin_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-linkedin-square" /></a></span> : '' }
                      { facebook_url ? <span><a href={facebook_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-facebook-square" /></a></span> : '' }
                      { plus_url ? <span><a href={plus_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-google-my-business-square" /></a></span> : '' }
                      { twitter_handle ? <span><a href={twitter_handle} target="_blank" rel="noopener noreferrer"><i className="oe-icon-twitter-square" /></a></span> : '' }
                      { pinterest_url ? <span><a href={pinterest_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-pinterest-square" /></a></span> : '' }
                      { yelp_url ? <span><a href={yelp_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-yelp-square" /></a></span> : '' }
                      { foursquare_url ? <span><a href={foursquare_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-foursquare-square" /></a></span> : '' }
                      { instagram_url ? <span><a href={instagram_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-instagram-square" /></a></span> : '' }
                      { zillow_url ? <span><a href={zillow_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-zillow-square" /></a></span> : '' }
                      { youtube_url ? <span><a href={youtube_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-youtube" /></a></span> : '' }
                      { avvo_url ? <span><a href={avvo_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-avvo" /></a></span> : '' }
                      { lawyers_url ? <span><a href={lawyers_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-lawyers-circle" /></a></span> : '' }
                      { realtor_url ? <span><a href={realtor_url} target="_blank" rel="noopener noreferrer"><i className="oe-icon-realtor-circle" /></a></span> : '' }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <ul className="footer-nav-links">
              <li><Link to="/">Home</Link></li>
              { newsletters_active
                && (<li><Link to="/newsletters">{newsletters_nav_title}</Link></li>)
              }
              { show_reviews ? <li><Link to="/reviews">{reviews_nav_title}</Link></li> : null }
              <li><Link to="/contact">{contact_nav_title}</Link></li>
              { video_page_active
                && (<li><Link to="/videos">{video_page_nav_title}</Link></li>)
              }
              {
                custom_page_enabled
                && custom_page_navbar_title
                && <li><CustomPageLink title={custom_page_navbar_title} /></li>
              }
              {
                custom_header_links && custom_header_links.length !== 0
                  ? custom_header_links.map(link => <li key={uuidv4()}><CustomLink path={link.path} label={link.label} class="dropdown-element" /></li>)
                  : null
              }
              {renderCustomLink1()}
              {renderCustomLink2()}
            </ul>
          </div>
          {
            vertical !== 39
            && (
            <div className="col-sm-4">
              <FooterSubscribe colorPalette={color_palette} />
            </div>
            )
          }
        </div>
      </div>
      <div className="container">
        <div className="row mb-5 disclaimer-cert-container">
          { disclaimer
            && (
              <div className="col text-center">
                <Disclaimer disclaimer={parsedDisclaimerText} />
              </div>
            )
          }
          { certification_image
            && (
              <div className="col mt-3 text-center">
                <img src={certification_image} alt="Certification" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(null)(Footer));
