import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modalActions';
import Modal from './Modal';

const SubmitSuccessModal = ({
  hideModal,
  companyUrl,
  profile,
  message,
}) => {
  const openCompanyUrl = (companyUrl) => {
    window.open(companyUrl, '_top');
  };

  const onClose = () => {
    hideModal();
    if (profile && profile.user.ff_business_profile === false && companyUrl) {
      openCompanyUrl(companyUrl);
    }
  };

  return (
    <Modal
      onClose={onClose}
    >
      <div className="col review-card">
        <div className="flex-row-reverse">
          <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="check_mark">
          <div className="sa-icon sa-success animate">
            <span className="sa-line sa-tip animateSuccessTip" />
            <span className="sa-line sa-long animateSuccessLong" />
            <div className="sa-placeholder" />
            <div className="sa-fix" />
          </div>
        </div>
        <h2 className="submitSuccessMessage">{message}</h2>
      </div>
    </Modal>
  );
};

SubmitSuccessModal.propTypes = ({
  hideModal: PropTypes.func.isRequired,
  message: PropTypes.string,
});

SubmitSuccessModal.defaultProps = {
  message: 'Thank you for your submission!',
};

export default connect(null, { hideModal })(SubmitSuccessModal);
