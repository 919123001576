import React from 'react';

const ImageLeft = props => (
  <div className="row custom-layout">
    <div className="col-sm-6 custom-left"><img src={props.image} alt="custom page" /></div>
    <div className="col-sm-6 custom-right">{props.text}</div>
  </div>
);

export default ImageLeft;
