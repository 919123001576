import React from 'react';

const Greeting = props => (
  <div className="col text-center greeting">
    <h1>{props.title}</h1>
    <p>{props.subheading}</p>
  </div>
);

export default Greeting;
