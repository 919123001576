import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
import { Field, reduxForm } from 'redux-form';
import Spinner from '../Spinner';
import { postReviewFormRequest } from '../../../actions/profileActions';
import normalizePhone from '../../../util/normalizePhone';
import { hideModal, showModal } from '../../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../../actions/types';
import Modal from './Modal';
import { toggleProfileColorPalette, isOEDomain } from '../../../util/helperFuncs';
import ReCaptchaV2 from '../Google/ReCaptchaV2';

class ReviewFormModal extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    rating: 5,
    formValues: null,
  }

  onStarClick(nextValue) {
    this.setState({ rating: nextValue }, () => {});
  }

  reCaptchaRef = React.createRef();

  textareaRef = null;

  onTextareaRef = (textareaRef) => {
    if (textareaRef) {
      this.textareaRef = textareaRef;
      this.textareaRef.focus();
    }
  }

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  submitForm = () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = async (values) => {
      const { showModal } = this.props;
      const { profile: { contact, campaign_id } } = this.props.profile;
      const { form_key } = this.state;
      const obj = {
        form_key: '',
        form_name: '',
        form_data: {},
      };

      const form = document.getElementsByTagName('form');
      const formName = form[1].name; // Use 2nd form index as the modal comes after the footer form in the DOM structure
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.form_data = Object.assign(obj.form_data, values);
      obj.form_data.rating = this.state.rating.toString(); // Add rating from StarRating Component
      obj.form_name = formName;

      await this.props.postReviewFormRequest(obj);

      showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
    };

    onCreateSubmitObj(values);
  }

  onClose = () => {
    hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  };

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderPhoneField = field => (
    <div className="form-group">
      <input
        {...field.input}
        type={field.type}
        id={field.id}
        className={field.className || ''}
        required={field.required}
      />
      <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
    </div>
  );

  renderTextAreaField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    return (
      <div className="form-group general-inquiry">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
          rows="4"
          autoFocus
          ref={this.onTextareaRef}
        />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  render() {
    const onClose = () => {
      hideModal();

      if (afterClose) {
        afterClose();
      }
    };
    const {
      profile,
      loading,
      headshot,
      hideModal,
      afterClose,
      handleSubmit,
      submitting,
      invalid,
    } = this.props;

    const { btnDisabled, rating } = this.state;

    const url = profile.profile.user.landing_page_host;
    const isCustomDomain = !isOEDomain(url);

    let reviewFormContent;

    const handleSubmitBtnClick = () => {
      // Get form key from the profile
      this.setState({ form_key: this.props.form_key });

      const withBufferTimeout = () => {
        setTimeout(() => {
          this.setState({ btnDisabled: true });
        }, 1000);
      };

      withBufferTimeout();
    };

    if (loading) {
      reviewFormContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      reviewFormContent = (
        <Modal
          profile={profile}
          headshot={headshot}
          onClose={onClose}
        >
          <div className="col modal-form">
            <div className="flex-row-reverse">
              <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {
              headshot
              && (
              <div className="row">
                <div className="col text-center">
                  <div className="headshot-image mx-auto">
                    <img
                      src={headshot}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              )
            }
            {/* <!-- Contact Form Start --> */}
            <div className="row">
              <div className="col text-left">
                <small>* = required fields</small>
                {/* TODO: Fix required on all Field tags as they are all in place to fix a bug that raises the labels by default */}
                <form id="reviewV2modal" name="reviewV2modal" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                  <p className="text-left font-weight-bold">Choose a rating to give us based on your experience.</p>
                  <div className="container">
                    <div className="row">
                      <StarRatingComponent
                        name="rating"
                        starCount={5}
                        onStarClick={this.onStarClick.bind(this)}
                        renderStarIcon={() => <i className="fas fa-star fa-2x" />}
                        value={rating}
                        className="modal-star-rating "
                      />
                    </div>
                  </div>
                  <div className="container modal-form">
                    <div className="row">
                      Give us some feedback on how your experience was with us.
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        name="review"
                        id="review-form-modal"
                        component={this.renderTextAreaField}
                        for="message"
                        className=""
                        required
                      />
                    </div>
                  </div>
                  <React.Fragment>
                    <div className="container modal-form">
                      <div className="row">
                        <p className="text-left font-weight-bold modal-section-message">Let us know who you are. (Only first name will be displayed.)</p>
                        <Field
                          type="text"
                          name="first_name"
                          id="first-name"
                          component={this.renderField}
                          for="first_name"
                          className=""
                          label="First Name*"
                          required
                        />
                        <Field
                          type="text"
                          name="last_name"
                          id="last-name"
                          component={this.renderField}
                          for="last_name"
                          className=""
                          label="Last Name"
                          required
                        />
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="container modal-form">
                      <div className="row">
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          component={this.renderField}
                          for="email"
                          className=""
                          label="Email*"
                          required
                        />
                        <Field
                          type="tel"
                          name="phone"
                          id="phone"
                          component={this.renderPhoneField}
                          for="phone"
                          className=""
                          label="Phone"
                          normalize={normalizePhone}
                          required
                        />

                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="container modal-form">
                      <div className="row">
                        <p className="text-left font-weight-bold modal-section-message">Location:</p>

                      </div>
                      <div className="row">
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          component={this.renderField}
                          for="city"
                          className=""
                          label="City"
                          required
                        />
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          component={this.renderField}
                          for="state"
                          className=""
                          label="State/Province/Region"
                          required
                        />
                      </div>
                    </div>
                    <div className="container modal-form">
                      <div className="row">
                        <Field
                          type="text"
                          name="country"
                          id="country"
                          component={this.renderField}
                          for="country"
                          className=""
                          label="Country"
                          required
                        />
                      </div>
                    </div>
                  </React.Fragment>
                  {!isCustomDomain && (
                    <ReCaptchaV2
                      onVerify={this.submitForm}
                      reCaptchaRef={this.reCaptchaRef}
                    />
                  )}
                  <div className="row">
                    <div className="col text-center submit-btn-wrapper mb-3">
                      <p>By clicking 'Submit', you agree to be contacted using the contact information you have provided above.</p>
                      <button
                        className="contact-btn btn btn-large"
                        type="submit"
                        name="action"
                        onClick={handleSubmitBtnClick}
                        disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                        style={toggleProfileColorPalette(profile.profile, 'contactButton')}
                      >Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Contact Form End --> */}

          </div>
        </Modal>
      );
    }

    return (
      <div>
        { reviewFormContent }
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.review && (values.review.includes('<') || values.review.includes('&lt;'))) {
    errors.review = 'Invalid text entered';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (values.first_name && (values.first_name.includes('<') || values.first_name.includes('&lt;'))) {
    errors.first_name = 'Invalid text entered';
  }
  if (values.last_name && (values.last_name.includes('<') || values.last_name.includes('&lt;'))) {
    errors.last_name = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  if (values.city && (values.city.includes('<') || values.city.includes('&lt;'))) {
    errors.city = 'Invalid text entered';
  }
  if (values.state && (values.state.includes('<') || values.state.includes('&lt;'))) {
    errors.state = 'Invalid text entered';
  }
  if (values.country && (values.country.includes('<') || values.country.includes('&lt;'))) {
    errors.country = 'Invalid text entered';
  }

  return errors;
};

ReviewFormModal.defaultProps = {
  loading: false,
  afterClose: null,
};

ReviewFormModal.propTypes = ({
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  headshot: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  afterClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
});

const mapStateToProps = state => ({
  profile: state.profile,
});


export default compose(
  connect(mapStateToProps, { hideModal, showModal, postReviewFormRequest }),
  reduxForm({ validate, form: 'ReviewForm' }),
)(ReviewFormModal);
