import React, { Component } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import DocumentTitle from 'react-document-title';
import { getCustomPage } from '../../actions/profileActions';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import ImageLeft from './ImageLeft';
import ImageRight from './ImageRight';
import TextOnly from './TextOnly';
import Spinner from '../common/Spinner';
import { loadTitle } from '../../util/helperFuncs';

class CustomPage extends Component {
  constructor(props) {
    super(props);
    this.setLayout = this.setLayout.bind(this);
  }

  componentDidMount = async () => {
    this.getCustomPageData();
  }

  getCustomPageData() {
    const { getCustomPage, location: { pathname } } = this.props;
    getCustomPage(pathname);
  }

  setLayout(option, image, text) {
    switch (option) {
      case 'option1':
        return <ImageRight image={image} text={text} />;
      case 'option2':
        return <ImageLeft image={image} text={text} />;
      case 'option3':
        return <TextOnly text={text} />;
      default:
        return null;
    }
  }

  render() {
    const { profile: { profile, custompage } } = this.props;

    const {
      info: {
        custom_page_navbar_title,
        custom_page_text,
        custom_page_layout,
        custom_page_html,
        custom_page_title,
      }, page_title,
    } = profile;

    let CustomPageContent;

    if (profile === null || custompage === null) {
      CustomPageContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      const { base_image } = custompage;
      CustomPageContent = (
        <DocumentTitle title={loadTitle(page_title, custom_page_navbar_title)}>
          <div>
            <HeaderSmall profile={profile}>
              <Greeting title={custom_page_title} subheading="" />
            </HeaderSmall>
            <div className="custom-container">
              {
            custom_page_layout === 'option4'
              ? <div className="custom-layout">{Parser(custom_page_html)}</div>
              : this.setLayout(custom_page_layout, base_image, Parser(custom_page_text))
          }
            </div>
          </div>
        </DocumentTitle>
      );
    }
    return (
      <div className="newsletters">
        {CustomPageContent}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  custompage: state.profile.custompage,
});

export default connect(mapStateToProps, { getCustomPage })(CustomPage);
