import React from 'react';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      messageResponseShown: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showMessageResponse = this.showMessageResponse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    this.handleSubmit();
    this.showMessageResponse();
  }

  showMessageResponse() {
    if (this.state.messageResponseShown) {
      return null;
    }
    this.setState({ messageResponseShown: true });
    setTimeout(() => {
      this.props.messageResponse();
    }, 2000);

    return false;
  }

  handleSubmit() {
    const { message } = this.state;
    const user = 'You';
    const messageObj = {
      user,
      message,
    };
    this.props.onMessageSubmit(messageObj);
    this.setState({ message: '' });
  }

  handleInputChange(event) {
    this.setState({ message: event.target.value });
  }

  render() {
    return (
      <div>
        <form className="chat-form" onSubmit={this.onSubmit}>
          <div className="input-group chat-input container mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Type your message here"
              value={this.state.message}
              onChange={this.handleInputChange}
            />
            <div className="input-group-append">
              <button type="submit" className="btn" style={togglePropsColorPalette(this.props, 'contactButton')}>SEND</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ChatInput;
