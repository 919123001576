import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InvisibleReCaptcha from 'react-google-invisible-recaptcha';

const ReCaptchaV2 = props => props.reCaptchaV2SiteKey && (
  <InvisibleReCaptcha
    onResolved={props.onVerify}
    ref={props.reCaptchaRef}
    sitekey={props.reCaptchaV2SiteKey}
  />
);

ReCaptchaV2.propTypes = {
  reCaptchaRef: PropTypes.func,
  reCaptchaV2SiteKey: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reCaptchaV2SiteKey: state.secrets.reCaptchaV2SiteKey,
});

export default connect(mapStateToProps)(ReCaptchaV2);
