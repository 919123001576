import React from 'react';
import { Link } from 'react-router-dom';

const PageLinks = (props) => {
  const {
    leftItem, rightItem, rightUrl, leftUrl, showNotification, linkHeader, linkColor, dividerColor,
  } = props;

  return (
    <div className="col header-links-component text-center">
      <h2 data-testid="subhead-title">{linkHeader}</h2>
      <p className="pagelink text-center"><Link to={leftUrl} style={linkColor}>{leftItem}</Link></p>
      <p className="pagelink">
        <span className="divider" style={dividerColor} />
      </p>
      {rightUrl === 'reviews'
        ? <div className="pagelink text-center" onClick={showNotification} role="button" tabIndex={0} style={linkColor}>Leave a Review</div>
        : <p className="pagelink text-center"><Link to={rightUrl} style={linkColor}>{rightItem}</Link></p>
      }
    </div>

  );
};

export default PageLinks;
