import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { loadTitle, toggleProfileColorPalette } from '../../util/helperFuncs';
import { getProfile } from '../../actions/profileActions';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';

const KWR = (props) => {
  const { profile: { profile }, loading } = props;
  const {
    info: { reviews_active }, page_title, newsletters, reviews, color_palette,
  } = profile;

  let KWRContent;

  if (profile === null || loading) {
    KWRContent = (
      <div className="container">
        <div className="row">
          <div className="col">
            <Spinner />
          </div>
        </div>
      </div>
    );
  } else {
    KWRContent = (
      <DocumentTitle title={loadTitle(page_title, 'Join Our Team')}>
        <div>
          <HeaderSmall profile={profile}>
            <Greeting title="Join Our Team" subheading="" />
          </HeaderSmall>
          <main role="main" className="container">
            <div className="text-center kwr-heading">
              <h1 className="font-weight-normal">Careers Worth Having. Businesses Worth Owning.</h1>
            </div>
            <div className="row">
              <div className="col mt-3">
                <h6 className="kwr-content mx-auto">Real Estate Licensing</h6>
                <p className="kwr-content mx-auto">
                There are several steps to obtaining a real estate license in the U.S., though requirements
                can vary from state to state. Before you can practice as a licensed real estate agent, you must
                complete courses -- either online or in a classroom environment -- and pass an examination. The number
                of hours required, as well as passing scores and types of classes, differ by state.
                </p>
                <p className="kwr-content mx-auto">
                Other requirements are also necessary, including a background check and an affiliation with a managing
                or sponsoring broker for at least the first two years of your career.
                </p>
                <p className="kwr-content mx-auto">
                For more information about licensing, please <Link to="/apply" style={toggleProfileColorPalette(profile, 'link')}>contact us</Link>. We can help you navigate the
                process of beginning your real estate career.
                </p>
                <h6 className="kwr-content mx-auto">Continuing Education</h6>
                <p className="kwr-content mx-auto">
                Staying abreast of industry changes and learning more about the field are important aspects of a
                career in real estate. Every real estate agent has continuing education requirements in order to maintain licensure.
                </p>
                <p className="kwr-content mx-auto">
                Though real estate schools offer courses to fulfill these obligations, Keller Williams provides its own in-depth
                training options through our Market Center. Our curriculum leads the industry, helping real estate agents build skills
                and increase productivity and profitability.
                </p>
                <p className="kwr-content mx-auto">
                  <Link to="/apply" style={toggleProfileColorPalette(profile, 'link')}>Contact us</Link> to learn more about continuing education and the cutting-edge training we provide.
                </p>
                <h6 className="kwr-content mx-auto">Coaching</h6>
                <p className="kwr-content mx-auto">
                Professional development doesn't end once you have your real estate license. Keller Williams sets real
                estate agents up for success with award-winning coaching and sales training. From conferences and weekly
                group courses to in-depth, one-on-one time, Keller Williams offers innovative, proprietary resources that
                help our agents grow and better serve their clients.
                </p>
                <p className="kwr-content mx-auto">
                To learn more about our coaching and sales training program, please <Link to="/apply" style={toggleProfileColorPalette(profile, 'link')}>contact us</Link>.
                </p>
              </div>
            </div>
            <div className="row subhead">
              <div className="col text-center kwr-button-container mt-5">
                <Link className="mx-auto kwr-button" to="/apply" style={toggleProfileColorPalette(profile, 'contactButton')}>Join Our Team</Link>
              </div>
            </div>
            {newsletters && <NewslettersCommon profile={profile} colorPalette={color_palette} />}
            {reviews && reviews_active && <ReviewsCommon profile={profile} />}
          </main>
        </div>
      </DocumentTitle>
    );
  }

  return (
    <div className="kwr">
      {KWRContent}
    </div>
  );
};

KWR.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(KWR);
