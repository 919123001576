import axios from 'axios';

export default class SecretsService {
  getSecret = name => axios.get(`/v2/secrets?name=${name}`);

  getReCaptchaV3SiteKey = async () => {
    const response = await this.getSecret('google/recaptcha/v3');

    if (response.data.data) {
      const siteKeyContainer = JSON.parse(response.data.data);

      return siteKeyContainer.site_key;
    }

    return null;
  }

  getReCaptchaV2SiteKey = async () => {
    const response = await this.getSecret('google/invisible_recaptcha');

    if (response.data.data) {
      const siteKeyContainer = JSON.parse(response.data.data);

      return siteKeyContainer.site;
    }

    return null;
  }
}
