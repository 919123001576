import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import DatePicker from 'react-datepicker';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';
import { postProfileFormRequest, getProfile } from '../../actions/profileActions';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../actions/types';
import normalizePhone from '../../util/normalizePhone';
import { verticalList } from '../../util/formTypeConfig';
import { loadTitle, toggleProfileColorPalette, isOEDomain } from '../../util/helperFuncs';
import 'react-datepicker/dist/react-datepicker.css';
import { initGA, logPageView } from '../common/Google/ReactGA';
import ReCaptchaV2 from '../common/Google/ReCaptchaV2';

class Contact extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    campaign_id: null,
    contact_id: null,
    startDate: '',
    time: '',
    ctaText: 'Submit',
    errors: {},
    formValues: null,
  };

  componentDidMount = () => {
    const { change, triggerTypeId } = this.props;
    window.scrollTo(0, 0);
    initGA();
    logPageView();
    change('lead_trigger_type_id', triggerTypeId);
  }

  componentDidUpdate(prevProps, prevState) {
    let text = '';

    const genOptions = this.findVerticalOptions();

    const updateCTAText = (labelValue, userVertical, labelCTAText) => {
      if (this.props.reasonSelected === labelValue && this.props.profile.profile.user.vertical === userVertical) {
        text = genOptions.find(text => text.ctaText === labelCTAText);
        text = text.ctaText;
        if (prevState.ctaText !== text) {
          this.setState({ ctaText: text });
        }
      }
    };

    // Call function for the indicated verticals to render specific CTA text when
    // certain dropdowns are selected
    updateCTAText('16', 1, 'Get Estimate');
    updateCTAText('20', 1, 'Request Listings');
    updateCTAText('16', 29, 'Get Estimate');
    updateCTAText('20', 29, 'Request Listings');
    updateCTAText('16', 18, 'Get Estimate');
    updateCTAText('20', 18, 'Request Listings');
    updateCTAText('16', 27, 'Get Estimate');
    updateCTAText('20', 27, 'Request Listings');
    updateCTAText('13', 4, 'Request Calculation');
    updateCTAText('14', 4, 'Request Consultation');
    updateCTAText('13', 19, 'Request Calculation');
    updateCTAText('14', 19, 'Request Consultation');
    updateCTAText('17', 6, 'Get Quote');
    updateCTAText('9', 2, 'Get a Quote');
    updateCTAText('12', 2, 'Get My Policy Review');
    updateCTAText('9', 24, 'Get a Quote');
    updateCTAText('12', 24, 'Get My Policy Review');
    updateCTAText('9', 33, 'Get a Quote');
    updateCTAText('12', 33, 'Get My Policy Review');
    updateCTAText('23', 22, 'Request Meeting');
    updateCTAText('10', 25, 'Submit Inquiry');
    updateCTAText('10', 31, 'Submit Inquiry');
    updateCTAText('10', 20, 'Submit Inquiry');
    updateCTAText('10', 26, 'Submit Inquiry');
    updateCTAText('10', 32, 'Submit Inquiry');
    updateCTAText('10', 35, 'Submit Inquiry');
    updateCTAText('10', 34, 'Submit Inquiry');
    updateCTAText('16', 11, 'Request Estimate');
    updateCTAText('18', 21, 'Request Consultation');
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  submitForm = async () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = async (values) => {
      const { startDate } = this.state;

      const {
        profile,
        profile: {
          form_key, contact, campaign_id,
        },
      } = this.props.profile;

      const { reasonSelected, showModal } = this.props;

      const obj = {
        form_key: '',
        form_name: '',
        lead_trigger_type_id: null,
        form_data: {},
      };

      // Get the date from datepicker and format it
      const formatDate = str => str = str.toLocaleString().split(',')[0].split(' ')[0];

      const form = document.getElementsByTagName('form');
      const formName = form[0].name;
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.lead_trigger_type_id = Number(values.lead_trigger_type_id);
      delete values.lead_trigger_type_id; // Remove lead_trigger_type_id from payload
      obj.form_data = Object.assign(obj.form_data, values);
      // Check and add Verticals that use a 'date' field for the form to the formData payload
      if (
        (profile.user.vertical === 11
        || profile.user.vertical === 12
        || profile.user.vertical === 7
        || profile.user.vertical === 8
        || profile.user.vertical === 37
        || profile.user.vertical === 39)
        && reasonSelected === '22'
      ) {
        obj.form_data.date = formatDate(startDate);
      }
      if (
        (profile.user.vertical === 26
        || profile.user.vertical === 32
        || profile.user.vertical === 25
        || profile.user.vertical === 31
        || profile.user.vertical === 35
        || profile.user.vertical === 34
        || profile.user.vertical === 20)
      ) {
        obj.form_data.start_date = formatDate(startDate);
      }
      obj.form_name = formName;

      await this.props.postProfileFormRequest(obj);

      showModal(MODAL_TYPE_SUBMIT_SUCCESS, {
        companyUrl: profile.info.company_url,
        profile,
      });
    };

    await onCreateSubmitObj(values);

    this.setState({ startDate: '' });
  }

  setBtnDisabledStateFalse() {
    this.setState({ btnDisabled: false });
  }

  // Algorithm to pull out correct vertical options to be used
  // 'query' must be what comes back in base profile call format of 'KELLER_WILLIAMS_RECRUITING' for example
  findVerticalOptions = () => {
    const filterVerts = (query, arr) => arr.filter(item => item.verticalId === query);
    const getPropsProfile = this.props.profile.profile;
    const getVertOptions = filterVerts(getPropsProfile.user.vertical, verticalList);
    const genOptions = getVertOptions[0].options;
    return genOptions;
  }

  handleRadioChange(newTime) {
    this.setState({
      time: newTime,
    }, () => this.state.time);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    }, () => this.state.startDate,
    );
  }

  renderCTASelectField = (field) => {
    const { reasonSelected, triggerTypeId, profile: { profile } } = this.props;
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    // Algorithm to pull out correct vertical options to be used
    // 'query' must be what comes back in base profile call format of 'KELLER_WILLIAMS_RECRUITING' for example

    const selectOptions = this.findVerticalOptions()
      .filter((opt) => {
        const info = profile ? profile.info : null;
        return opt.showFlag && info ? info[opt.showFlag] : true;
      })
      .map(option => (
        <option key={option.label} value={option.value}>{option.label}</option>
      ));

    return (
      <div className="form-group">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={reasonSelected || triggerTypeId}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">Reason for Reaching Out</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderPhoneSelectField = (field) => {
    // Select options for phone type
    const phoneOptions = [
      { label: 'Select one', value: '' },
      { label: 'Home', value: 6 },
      { label: 'Mobile', value: 7 },
      { label: 'Work', value: 5 },
      { label: 'Other', value: 8 },
    ];

    const selectOptions = phoneOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group field-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">Phone type</label><i className="bar" />
      </div>
    );
  }

  renderRecruitingLicenseSelectField = (field) => {
    // Select options for license options
    const licenseOptions = [
      { label: 'Select one', value: 'N/A' },
      { label: 'Yes, I have a license', value: 'Yes' },
      { label: 'No, I don\'t have a license', value: 'No' },
      { label: 'I\'m currently studying for my license', value: 'Studying for license' },
    ];

    const selectOptions = licenseOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group field-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">Do you have a real estate license?</label><i className="bar" />
      </div>
    );
  }

  renderRecruitingInterestSelectField = (field) => {
    // Select options for interest options
    const interestOptions = [
      { label: 'Select one', value: 'N/A' },
      { label: 'I\'m considering a career in Real Estate.', value: 'Considering a career in Real Estate' },
      { label: 'I\'m considering joining as an agent.', value: 'Considering joining as an agent' },
      { label: 'I\'d like to learn more about licensing and continuing education.', value: 'Wants to learn more about licensing and continuing education' },
    ];

    const selectOptions = interestOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group field-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">What are you interested in?</label><i className="bar" />
      </div>
    );
  }

  renderKWRecruitingInterestSelectField = (field) => {
    // Select options for interest options
    const interestOptions = [
      { label: 'Select one', value: 'N/A' },
      { label: 'I\'m considering a career in Real Estate.', value: 'Considering a career in Real Estate' },
      { label: 'I\'m considering joining Keller Williams as an agent.', value: 'Considering joining as an agent' },
      { label: 'I\'d like to learn more about licensing and continuing education.', value: 'Wants to learn more about licensing and continuing education' },
    ];

    const selectOptions = interestOptions.map(option => (
      <option key={option.label} value={option.value}>{option.label}</option>
    ));
    return (
      <div className="form-group field-type-select">
        <select
          {...field.input}
          name={field.name}
          id={field.id}
          value={field.value}
          options={field.options}
        >
          {selectOptions}
        </select>
        <label htmlFor={field.id} className="control-label">What are you interested in?</label><i className="bar" />
      </div>
    );
  }

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group contact-input col">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderPhoneField = field => (
    <div className="form-group">
      <input
        {...field.input}
        type={field.type}
        id={field.id}
        className={field.className || ''}
        required={field.required}
      />
      <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
    </div>
  );


  renderTextAreaField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    return (
      <div className="form-group general-inquiry mx-auto">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
          rows="5"
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label>
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderTextAreaFieldReferFriend = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group referrer-message mx-auto">
        <textarea
          {...field.input}
          type="text"
          id={field.id}
          className={field.className || ''}
          required={field.required}
          rows="5"
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label>
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderReferFriendEmailField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group referrer-email">
        <input
          {...field.input}
          type="email"
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderTimeOfDayRadioField = () => (
    <div className="form-radio">
      <p className="date-time-control-label">Time of Day</p>
      <div className="radio">
        <label>
          <Field name="time" component="input" type="radio" value="morning" /><i className="helper" />
          {' '}
            Morning
        </label>
      </div>
      <div className="radio">
        <label>
          <Field name="time" component="input" type="radio" value="afternoon" /><i className="helper" />
          {' '}
            Afternoon
        </label>
      </div>
      <div className="radio">
        <label>
          <Field name="time" component="input" type="radio" value="evening" /><i className="helper" />
          {' '}
            Evening
        </label>
      </div>
    </div>
  )

  render() {
    const { profile, profile: { color_palette }, loading } = this.props.profile;
    const {
      handleSubmit,
      submitting,
      invalid,
      reset,
      reasonSelected,
      recruitingLicenseSelected,
    } = this.props;

    const { btnDisabled } = this.state;

    const url = profile.user.landing_page_host;
    const isCustomDomain = !isOEDomain(url);

    let contactContent;

    const toggleCTAText = () => {
      const toggleCTASubmitText = () => this.setState({ ctaText: 'Submit' }, () => {});
      return toggleCTASubmitText();
    };

    const handleSubmitBtnClick = () => {
      // Get form key from the profile
      this.setState({ form_key: profile.form_key }); // Also set button back to disabled

      // Function to allow simultaneous modal trigger and reading of form field values
      // Sets a buffer timeout to allow values to be read before resetting form fields
      // Manually reset dropdown fields as the reset() only clears all other input fields
      const withBufferTimeout = () => {
        setTimeout(() => {
          const selectDropdown = document.getElementById('lead-trigger-type-id');
          const phoneTypeDropdown = document.getElementById('phone-type');
          selectDropdown.value = '';
          phoneTypeDropdown.value = '';
          reset();
          this.setState({ btnDisabled: true });
        }, 1000);
      };
      withBufferTimeout();
    };

    if (loading) {
      contactContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      contactContent = (
        <DocumentTitle title={loadTitle(profile.page_title, 'Contact', profile.info.contact_nav_title_capitalized)}>
          <div className="contact">
            <HeaderSmall profile={profile}>
              <Greeting title={profile.info.contact_page_title} subheading="" profile={profile} />
            </HeaderSmall>
            <div className="container">
              <div className="row">
                <div className="col">
                  <p className="form-context-msg text-center">Please provide your information and we'll get back to you as
                      soon as possible.
                  </p>
                  {/* <!-- Contact Form Start --> */}
                  <div className="row">
                    <div className="col">
                      <div className="required-fields">
                        <small>* = required fields</small>
                      </div>
                      {/* TODO: Fix required on all Field tags as they are all in place to fix a bug that raises the labels by default */}
                      <form id="contactV2" name="contactV2" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                        <div className="container contact-form-container">
                          <div className="row">
                            <Field
                              type="select"
                              name="lead_trigger_type_id"
                              id="lead-trigger-type-id"
                              component={this.renderCTASelectField}
                              for="lead_trigger_type_id"
                              label={this.selectOptions}
                              onChange={toggleCTAText}
                            />
                            <React.Fragment>
                              {(reasonSelected === '11'
                              || reasonSelected === '15') && (
                              <Field
                                type="email"
                                name="referrer_email"
                                id="referrer-email"
                                component={this.renderField}
                                for="referrer_email"
                                className=""
                                label="Email Address*"
                                required
                              />
                              )}
                            </React.Fragment>
                            <React.Fragment>
                              {(reasonSelected !== '11'
                              && reasonSelected !== '15') && (
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                component={this.renderField}
                                for="email"
                                className=""
                                label="Email Address*"
                                required
                              />
                              )}
                            </React.Fragment>
                          </div>
                        </div>
                        <React.Fragment>
                          {(reasonSelected === '11'
                          || reasonSelected === '15') && (
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="referrer_first_name"
                                  id="referrer-first-name"
                                  component={this.renderField}
                                  for="referrer_first_name"
                                  className=""
                                  label="First Name*"
                                  required
                                />
                                <Field
                                  type="text"
                                  name="referrer_last_name"
                                  id="referrer-last-name"
                                  component={this.renderField}
                                  for="referrer_last_name"
                                  className=""
                                  label="Last Name"
                                  required
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                        <React.Fragment>
                          {(reasonSelected !== '11'
                          && reasonSelected !== '15') && (
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="first_name"
                                  id="first-name"
                                  component={this.renderField}
                                  for="first_name"
                                  className=""
                                  label="First Name*"
                                  required
                                />
                                <Field
                                  type="text"
                                  name="last_name"
                                  id="last-name"
                                  component={this.renderField}
                                  for="last_name"
                                  className=""
                                  label="Last Name"
                                  required
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                        <React.Fragment>
                          {(reasonSelected === '11'
                          || reasonSelected === '15') && (
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="tel"
                                  name="referrer_phone"
                                  id="referrer-phone"
                                  component={this.renderPhoneField}
                                  for="referrer_phone"
                                  className=""
                                  label="Phone"
                                  normalize={normalizePhone}
                                  required
                                />
                                <Field
                                  type="select"
                                  name="referrer_phone_type"
                                  id="referrer-phone-type"
                                  component={this.renderPhoneSelectField}
                                  for="referrer_phone_type"
                                  label={this.selectOptions}
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                        <React.Fragment>
                          {(reasonSelected !== '11'
                          && reasonSelected !== '15') && (
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="tel"
                                  name="phone"
                                  id="phone"
                                  component={this.renderPhoneField}
                                  for="phone"
                                  className=""
                                  label="Phone"
                                  normalize={normalizePhone}
                                  required
                                />
                                <Field
                                  type="select"
                                  name="phone_type"
                                  id="phone-type"
                                  component={this.renderPhoneSelectField}
                                  for="phone_type"
                                  label={this.selectOptions}
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>

                        {/* reasonSelect = 13 START */}
                        {reasonSelected === '13' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="number"
                                name="home_value"
                                id="home-value"
                                component={this.renderField}
                                for="home_value"
                                label="Home Price"
                                required
                              />
                              <Field
                                type="number"
                                name="down_payment"
                                id="home-down-payment"
                                component={this.renderField}
                                for="down_payment"
                                label="Down Payment"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="loan_type"
                                id="home-loan-type"
                                component={this.renderField}
                                for="loan_type"
                                label="Loan Type"
                                required
                              />
                              <Field
                                type="number"
                                name="interest_rate"
                                id="home-interest-rate"
                                component={this.renderField}
                                for="interest_rate"
                                label="Interest Rate"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="number"
                                name="loan_term"
                                id="home-loan-term"
                                component={this.renderField}
                                for="loan_term"
                                label="Term"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* reasonSelect = 13 END */}

                        {/* reasonSelect = 17 START */}
                        {reasonSelected === '17' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="description"
                                id="promo-message"
                                component={this.renderTextAreaField}
                                for="description"
                                className=""
                                label="Describe what you're looking for"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="number"
                                name="budget"
                                id="budget"
                                component={this.renderField}
                                for="budget"
                                className=""
                                label="Budget"
                                required
                              />
                              <Field
                                type="number"
                                name="quantity"
                                id="quantity"
                                component={this.renderField}
                                for="quantity"
                                className=""
                                label="Quantity Needed"
                                required
                              />
                            </div>
                          </div>

                        </React.Fragment>
                        )}
                        {/* reasonSelect = 17 END */}

                        {/* GENERAL INQUIRY START */}
                        {reasonSelected === '19' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="notes"
                                id="general-inquiry"
                                component={this.renderTextAreaField}
                                for="notes"
                                className=""
                                label="Add a message"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* GENERAL INQUIRY END */}

                        {/* REAL_ESTATE reasonSelect = 16 START */}
                        {(profile.user.vertical === 1
                        || profile.user.vertical === 27
                        || profile.user.vertical === 29
                        || profile.user.vertical === 18) && reasonSelected === '16' && (
                        <React.Fragment>
                          <div className="container contact-form-container mt-5">
                            <p className="text-center">What's your home worth? Provide your home information and I will get back to you so we can get your home appraisal underway.</p>
                            <div className="row">
                              <Field
                                type="text"
                                name="street"
                                id="street"
                                component={this.renderField}
                                for="street"
                                className=""
                                label="Street Address"
                                required
                              />
                              <Field
                                type="text"
                                name="city"
                                id="city"
                                component={this.renderField}
                                for="city"
                                className=""
                                label="City"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="state"
                                id="state"
                                component={this.renderField}
                                for="state"
                                className=""
                                label="State/Province/Region"
                                required
                              />
                              <Field
                                type="text"
                                name="zip"
                                id="zip-postal"
                                component={this.renderField}
                                for="zip"
                                className="hidden"
                                label="Zip/Postal Code"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* REAL_ESTATE END */}

                        {/* HOME SERVICES reasonSelect = 16 START */}
                        {(profile.user.vertical === 11
                        || profile.user.vertical === 7) && reasonSelected === '16' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="details"
                                id="home-services-inquiry"
                                component={this.renderTextAreaField}
                                for="details"
                                className=""
                                label="Describe the project or service you would like estimated"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* HOME SERVICES reasonSelect = 16 END */}

                        {/* LEGAL CRIMINAL DEFENSE reasonSelect = 18 START */}
                        {(profile.user.vertical === 21) && reasonSelected === '18' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="description"
                                id="legal-criminal-defense-info"
                                component={this.renderTextAreaField}
                                for="description"
                                className=""
                                label="Briefly Describe Your Case"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* LEGAL CRIMINAL DEFENSE reasonSelect = 18 END */}

                        {/* HOME SERVICES reasonSelect = 22 START */}
                        {(profile.user.vertical === 11
                        || profile.user.vertical === 7
                        || profile.user.vertical === 8
                        || profile.user.vertical === 37
                        || profile.user.vertical === 39) && reasonSelected === '22' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <div className="form-group">
                                <p className="date-time-control-label">Date</p>
                                <DatePicker
                                  selected={this.state.startDate === '' ? null : this.state.startDate}
                                  onChange={this.handleChange}
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="Click to select a date"
                                />
                              </div>

                              <Field
                                name="time"
                                component={this.renderTimeOfDayRadioField}
                                for="time"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="details"
                                id="home-additional-details"
                                component={this.renderTextAreaField}
                                for="details"
                                className=""
                                label="Additional Details"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* HOME SERVICES reasonSelect = 22 END */}

                        {/* WELLNESS reasonSelect = 22 START */}
                        {profile.user.vertical === 12 && reasonSelected === '22' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <div className="form-group">
                                <p className="date-time-control-label">Date</p>
                                <DatePicker
                                  selected={this.state.startDate === '' ? null : this.state.startDate}
                                  onChange={this.handleChange}
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="Click to select a date"
                                />
                              </div>

                              <Field
                                name="time"
                                component={this.renderTimeOfDayRadioField}
                                for="time"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="details"
                                id="additional-info"
                                component={this.renderTextAreaField}
                                for="details"
                                className=""
                                label="Additional Details"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* WELLNESS reasonSelect = 22 END */}

                        {/* HOME LISTINGS START */}
                        {reasonSelected === '20' && (
                        <React.Fragment>
                          <p className="text-center mt-5">Enter any location and/or budget criteria you would like us to use and we can pull relevant listings for you.</p>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="street"
                                id="home-listings-street-address"
                                component={this.renderField}
                                for="street"
                                className="hidden"
                                label="Street Address"
                                required
                              />
                              <Field
                                type="text"
                                name="city"
                                id="home-listings-city"
                                component={this.renderField}
                                for="city"
                                className="hidden"
                                label="City"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="state"
                                id="home-listings-state"
                                component={this.renderField}
                                for="state"
                                className="hidden"
                                label="State/Province/Region"
                                required
                              />
                              <Field
                                type="text"
                                name="zip"
                                id="home-listings-zip-postal"
                                component={this.renderField}
                                for="zip"
                                className=""
                                label="Zip/Postal Code"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="number"
                                name="min"
                                id="home-listings-min-price"
                                component={this.renderField}
                                for="min"
                                className=""
                                label="Minimum Price"
                                required
                              />
                              <Field
                                type="number"
                                name="max"
                                id="home-listings-max-price"
                                component={this.renderField}
                                for="max"
                                className=""
                                label="Maximum Price"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="desired_city"
                                id="home-listings-desired-city"
                                component={this.renderField}
                                for="desired_city"
                                className=""
                                label="Desired City"
                                required
                              />
                              <Field
                                type="text"
                                name="desired_state"
                                id="home-listings-desired-state"
                                component={this.renderField}
                                for="desired_state"
                                className=""
                                label="Desired State"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="areas"
                                id="home-listings-inquiry"
                                component={this.renderTextAreaField}
                                for="areas"
                                className=""
                                label="Desired Areas"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* HOME LISTINGS END */}

                        {/* JOIN TEAM START - MORTGAGE_RECRUITING */}
                        {(profile.user.vertical === 26
                        || profile.user.vertical === 32) && reasonSelected === '10' && (
                          <React.Fragment>
                            <div className="container contact-form-container">
                              <div className="row">
                                <div className="form-group">
                                  <p className="recruiting-date-time-control-label">When are you available to start?</p>
                                  <DatePicker
                                    selected={this.state.startDate === '' ? null : this.state.startDate}
                                    onChange={this.handleChange}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Click to select a date"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="additional_info"
                                  id="recruiting-additional-info"
                                  component={this.renderTextAreaField}
                                  className=""
                                  label="Any additional information that would be helpful"
                                  required
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {/* JOIN TEAM END - MORTGAGE_RECRUITING */}

                        {/* JOIN TEAM START - TWFG_INSURANCE_RECRUITING */}
                        {(profile.user.vertical === 35) && reasonSelected === '10' && (
                          <React.Fragment>
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="agent"
                                  id="recruiting-refer-agent"
                                  component={this.renderField}
                                  for="agent"
                                  className=""
                                  label={profile.user.vertical === 20 ? 'Referred by a KW Agent? What\'s their name?' : 'Referred by an agent? What\'s their name?'}
                                  required
                                />
                              </div>
                            </div>
                            <div className="container contact-form-container">
                              <div className="row">
                                <div className="form-group">
                                  <p className="recruiting-date-time-control-label">When are you available to start?</p>
                                  <DatePicker
                                    selected={this.state.startDate === '' ? null : this.state.startDate}
                                    onChange={this.handleChange}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Click to select a date"
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {/* JOIN TEAM END - TWFG_INSURANCE_RECRUITING */}

                        {/* JOIN TEAM START - REAL_ESTATE_RECRUITING */}
                        {(profile.user.vertical === 25
                        || profile.user.vertical === 31
                        || profile.user.vertical === 34
                        || profile.user.vertical === 20) && reasonSelected === '10' && (
                          <React.Fragment>
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="select"
                                  name="is_licensed"
                                  id="recruiting-license-select"
                                  component={this.renderRecruitingLicenseSelectField}
                                  for="is_licensed"
                                  className=""
                                  label={this.selectOptions}
                                  required
                                />
                                {recruitingLicenseSelected === 'Yes' && (
                                <Field
                                  type="text"
                                  name="license"
                                  id="recruiting-license-number"
                                  component={this.renderField}
                                  for="license"
                                  className=""
                                  label="What is your license number?"
                                  required
                                />
                                )}
                                <Field
                                  type="select"
                                  name="interest"
                                  id="recruiting-interested-in"
                                  component={profile.user.vertical === 20 ? this.renderKWRecruitingInterestSelectField : this.renderRecruitingInterestSelectField}
                                  for="interest"
                                  className=""
                                  label={this.selectOptions}
                                  required
                                />
                              </div>
                            </div>
                            <div className="container contact-form-container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="agent"
                                  id="recruiting-refer-agent"
                                  component={this.renderField}
                                  for="agent"
                                  className="recruiting-refer-agent"
                                  label={profile.user.vertical === 20 ? 'Referred by a KW Agent? What\'s their name?' : 'Referred by an agent? What\'s their name?'}
                                  required
                                />
                              </div>
                            </div>
                            <div className="container contact-form-container">
                              <div className="row">
                                <div className="form-group">
                                  <p className="recruiting-date-time-control-label">When are you available to start?</p>
                                  <DatePicker
                                    selected={this.state.startDate === '' ? null : this.state.startDate}
                                    onChange={this.handleChange}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Click to select a date"
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {/* JOIN TEAM - REAL_ESTATE_RECRUITING END */}

                        {/* REFER FRIEND START */}
                        {(reasonSelected === '11'
                        || reasonSelected === '15') && (
                        <React.Fragment>
                          <p className="form-subhead text-center mt-5">About your referral</p>
                          <div className="container contact-form-container">
                            <p className="text-center">Most of our business comes through referrals. I would greatly appreciate it if you would refer me someone you know that would love our services.</p>
                            <div className="row">
                              <Field
                                type="email"
                                name="email"
                                id="referrer-email"
                                component={this.renderReferFriendEmailField}
                                for="email"
                                className="referrer-email"
                                label="Email Address*"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="first_name"
                                id="referrer-first-name"
                                component={this.renderField}
                                for="first_name"
                                className=""
                                label="First Name*"
                                required
                              />
                              <Field
                                type="text"
                                name="last_name"
                                id="referrer-last-name"
                                component={this.renderField}
                                for="last_name"
                                className=""
                                label="Last Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="tel"
                                name="phone"
                                id="referrer-phone"
                                component={this.renderPhoneField}
                                for="phone"
                                className=""
                                label="Phone"
                                normalize={normalizePhone}
                                required
                              />
                              <Field
                                type="select"
                                name="phone_type"
                                id="referrer-phone-type"
                                component={this.renderPhoneSelectField}
                                for="phone_type"
                                label={this.selectOptions}
                              />
                            </div>
                          </div>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="notes"
                                id="referrer-message"
                                component={this.renderTextAreaFieldReferFriend}
                                for="notes"
                                className=""
                                label="Add a message"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* REFER FRIEND END */}

                        {/* FINANCIAL ADVISORS reasonSelect = 25 START */}
                        {reasonSelected === '25' && (
                        <React.Fragment>
                          <div className="container contact-form-container">
                            <div className="row">
                              <Field
                                type="text"
                                name="description"
                                id="financial-advisors-description"
                                component={this.renderTextAreaField}
                                for="description"
                                className=""
                                label="Briefly Describe Your Question"
                                required
                              />
                            </div>
                          </div>
                        </React.Fragment>
                        )}
                        {/* FINANCIAL ADVISORS reasonSelect = 25 END */}
                        {!isCustomDomain && (
                          <ReCaptchaV2
                            onVerify={this.submitForm}
                            reCaptchaRef={this.reCaptchaRef}
                          />
                        )}
                        <div className="row">
                          <div className="col text-center submit-btn-wrapper mb-5">
                            <p>By clicking '{this.state.ctaText}', you agree to be contacted using the contact information you have provided above.</p>
                            <button
                              className="contact-btn btn btn-large"
                              type="submit"
                              name="action"
                              onClick={handleSubmitBtnClick}
                              disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                              style={toggleProfileColorPalette(profile, 'contactButton')}
                            >{this.state.ctaText}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {
                  profile.user.ff_business_profile
                    ? (
                      <div className="container">
                        { profile.newsletters ? <NewslettersCommon profile={profile} colorPalette={color_palette} /> : null }
                        { profile.info.reviews_active && profile.reviews ? <ReviewsCommon profile={profile} colorPalette={color_palette} /> : null }
                      </div>
                    ) : null
                }
              </div>
            </div>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <div className="contact">
        {contactContent}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.referrer_first_name) {
    errors.referrer_first_name = 'First name is required';
  }
  if (values.referrer_last_name && (values.referrer_last_name.includes('<') || values.referrer_last_name.includes('&lt;'))) {
    errors.referrer_last_name = 'Invalid text entered';
  }
  if (values.first_name && (values.first_name.includes('<') || values.first_name.includes('&lt;'))) {
    errors.first_name = 'Invalid text entered';
  }
  if (values.last_name && (values.last_name.includes('<') || values.last_name.includes('&lt;'))) {
    errors.last_name = 'Invalid text entered';
  }
  if (values.loan_type && (values.loan_type.includes('<') || values.loan_type.includes('&lt;'))) {
    errors.loan_type = 'Invalid text entered';
  }
  if (values.description && (values.description.includes('<') || values.description.includes('&lt;'))) {
    errors.description = 'Invalid text entered';
  }
  if (values.notes && (values.notes.includes('<') || values.notes.includes('&lt;'))) {
    errors.notes = 'Invalid text entered';
  }
  if (values.street && (values.street.includes('<') || values.street.includes('&lt;'))) {
    errors.street = 'Invalid text entered';
  }
  if (values.city && (values.city.includes('<') || values.city.includes('&lt;'))) {
    errors.city = 'Invalid text entered';
  }
  if (values.state && (values.state.includes('<') || values.state.includes('&lt;'))) {
    errors.state = 'Invalid text entered';
  }
  if (values.zip && (values.zip.includes('<') || values.zip.includes('&lt;'))) {
    errors.zip = 'Invalid text entered';
  }
  if (values.details && (values.details.includes('<') || values.details.includes('&lt;'))) {
    errors.details = 'Invalid text entered';
  }
  if (values.desired_city && (values.desired_city.includes('<') || values.desired_city.includes('&lt;'))) {
    errors.desired_city = 'Invalid text entered';
  }
  if (values.desired_state && (values.desired_state.includes('<') || values.desired_state.includes('&lt;'))) {
    errors.desired_state = 'Invalid text entered';
  }
  if (values.areas && (values.areas.includes('<') || values.areas.includes('&lt;'))) {
    errors.areas = 'Invalid text entered';
  }
  if (values.additional_info && (values.additional_info.includes('<') || values.additional_info.includes('&lt;'))) {
    errors.additional_info = 'Invalid text entered';
  }
  if (values.agent && (values.agent.includes('<') || values.agent.includes('&lt;'))) {
    errors.agent = 'Invalid text entered';
  }
  if (values.license && (values.license.includes('<') || values.license.includes('&lt;'))) {
    errors.license = 'Invalid text entered';
  }
  if (!values.lead_trigger_type_id) {
    errors.lead_trigger_type_id = 'Please choose a valid option';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (values.referrer_first_name && (values.referrer_first_name.includes('<') || values.referrer_first_name.includes('&lt;'))) {
    errors.referrer_first_name = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  if (!values.referrer_email) {
    errors.referrer_email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.referrer_email)) {
    errors.referrer_email = 'Valid email required.';
  }
  if (values.min && Number.isNaN(Number(values.min))) {
    errors.min = 'Invalid price, must contain only numbers.';
  }
  if (values.min && values.max) {
    if (Number(values.min) > Number(values.max)) {
      errors.min = 'Minimum Price can not be greater than Maximum Price';
    }
  }
  if (values.max && Number.isNaN(Number(values.max))) {
    errors.max = 'Invalid price, must contain only numbers.';
  }
  if (values.min && values.max) {
    if (Number(values.max) < Number(values.min)) {
      errors.max = 'Maximum Price can not be less than Minimum Price';
    }
  }

  return errors;
};

Contact.defaultProps = {
  loading: true,
  ctaText: 'Submit',
  reasonSelected: null,
  recruitingLicenseSelected: null,
};

Contact.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  ctaText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  reasonSelected: PropTypes.string,
  recruitingLicenseSelected: PropTypes.string,
};

const mapStateToProps = state => ({
  profile: state.profile,
  reasonSelected: selector(state, 'lead_trigger_type_id'),
  recruitingLicenseSelected: selector(state, 'is_licensed'),
});

const selector = formValueSelector('ContactForm');

export default compose(
  connect(mapStateToProps, { getProfile, postProfileFormRequest, showModal }),
  reduxForm({ validate, form: 'ContactForm' }),
)(Contact);
