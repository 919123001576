import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReviewItem from '../../reviews/ReviewItem';
import PageLinks from '../PageLinks/PageLinks';
import { showModal } from '../../../actions/modalActions';
import { MODAL_TYPE_REVIEW_FORM } from '../../../actions/types';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

const Reviews = (props) => {
  const { profile } = props;
  if (!profile) return null;

  const showNotification = () => {
    props.showModal(MODAL_TYPE_REVIEW_FORM, {
      headshot: profile.headshot_image,
      fullName: profile.full_name,
      company: profile.info.company,
      form_key: profile.form_key,
    });
  };

  return (
    <div>
      {
        profile.reviews
          ? (
            <div
              data-testid="reviews-subcomponent"
              className="subhead"
            >
              <PageLinks
                linkHeader="Reviews"
                rightItem="Leave a Review"
                leftItem="See all Reviews"
                leftUrl="/reviews"
                rightUrl="reviews"
                showNotification={showNotification}
                linkColor={togglePropsColorPalette(props, 'link')}
                dividerColor={togglePropsColorPalette(props, 'divider')}
              />
              <div className="row reviews pt-3">
                {profile.reviews.map(review => (
                  <ReviewItem
                    key={review.review_id}
                    review={review}
                    profile={profile}
                  />
                ))}
              </div>
            </div>
          )
          : ''
      }
    </div>
  );
};

Reviews.propTypes = {
  profile: PropTypes.shape({
    reviews: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(null, { showModal })(Reviews);
