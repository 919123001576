import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_REVIEW_ITEM } from '../../actions/types';
import { truncateString } from '../../util/helperFuncs';

const ReviewItem = (props) => {
  const {
    profile, review,
  } = props;

  const { rating } = review;

  const companyLogos = [
    {
      name: 'FACEBOOK',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/facebook_logo.png',
    },
    {
      name: 'OUTBOUNDENGINE',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/oe_logo_trans_50.png',
    },
    {
      name: 'GOOGLE_REVIEWS',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/googlereviews_logo.png',
    },
    {
      name: 'YELP',
      logo_url: 'https://outboundengine.s3.amazonaws.com/resources/images/logos/reviews/yelp_logo.png',
    },
    {
      name: 'ZILLOW',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/zillow_logo.png',
    },
  ];

  const setLogo = (reviewCompanies, site) => {
    let logo;
    companyLogos.map(reviewCompany => (reviewCompany.name === site ? logo = reviewCompany.logo_url : null));
    return logo;
  };

  const showNotification = () => {
    props.showModal(MODAL_TYPE_REVIEW_ITEM, {
      headshot: profile.headshot_image,
      fullName: profile.full_name,
      company: profile.info.company,
      reviewSite: review.review_site,
      rating,
      review: review.review,
      firstName: review.first_name,
      lastName: review.last_name,
      city: review.city,
      state: review.state,
      date: review.date,
    });
  };

  // const listenKeyboard = (event) => {
  //   if (event.key === 'Escape' || event.keyCode === 27) {
  //     props.onClose();
  //   }
  // };

  return (
    <div className="col-sm-6 review-card">
      <div onClick={showNotification} className="card" tabIndex={0} role="button">
        <div className="card-body">
          <img
            className="card-logo"
            src={setLogo(companyLogos, review.review_site)}
            alt={`${review.review_site} logo`}
          />
          <StarRatingComponent
            name="review-rating"
            starCount={5}
            renderStarIcon={() => <i className="fas fa-star" />}
            value={rating}
          />
          { review
            ? (
              <React.Fragment>
                <p className="review-text">{review.review ? Parser(truncateString(review.review)) : '' }</p>
                <p className="reviewer-info">{review.first_name ? Parser(review.first_name) : ''} {review.last_name && `${Parser(review.last_name).slice(0, 1)}.`}
                  {review.first_name && review.city ? ' -' : ''} {review.city && Parser(review.city)}{review.city && review.state ? ',' : ''} {review.first_name && !review.city && review.state ? '- ' : ''}{review.state && Parser(review.state)}
                </p>
                <span data-testid="review-date" className="review-date">{review.date ? review.date : ''}</span>
              </React.Fragment>
            )
            : ''
          }
        </div>
      </div>
    </div>
  );
};

ReviewItem.propTypes = {
  profile: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(null, { showModal })(ReviewItem);
