import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { getVideos } from '../../actions/profileActions';
import { getVideoLinkAndTitle, loadTitle, toggleProfileColorPalette } from '../../util/helperFuncs';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import VideoItem from './VideoItem';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';
import { initGA, logPageView } from '../common/Google/ReactGA';

const uuidv1 = require('uuid/v1');

class Videos extends Component {
  componentDidMount = async () => {
    await this.props.getVideos();
    initGA();
    logPageView();
  };

  render() {
    const { profile: { profile, videos }, loading } = this.props;
    let videosContent;
    let videosArr;

    if (videos !== null && videos.videos.length > 0) {
      videosArr = videos.videos.map(video => getVideoLinkAndTitle(video));
    } else {
      videosArr = null;
    }

    if (profile === null || videos === null || loading) {
      videosContent = (
        <div className="container">
          <div className="row spinner">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      const {
        info: {
          videos_nav_title_capitalized,
          video_page_title,
          reviews_active,
        }, page_title, reviews, color_palette,
      } = profile;
      videosContent = (
        <DocumentTitle title={loadTitle(page_title, 'Videos', videos_nav_title_capitalized)}>
          <div>
            <HeaderSmall profile={profile}>
              <Greeting title={video_page_title} subheading="" />
            </HeaderSmall>
            {/* Main Content Start */}
            <main role="main" className="container">
              <div className="row subhead">
                <div className="col text-center mt-5 home-contact-btn">
                  <Link className="mx-auto contact-me-btn" to="/contact" style={toggleProfileColorPalette(profile, 'contactButton')}>Get in touch today</Link>
                </div>
              </div>
              <div className="row video-iframe">
                {videosArr !== null
                  && videosArr.map(vid => (
                    <VideoItem
                      key={uuidv1()}
                      videoLink={vid.link}
                      title={vid.title}
                    />
                  ),
                  )}
                <div className="container">
                  { reviews && reviews_active
                    ? <ReviewsCommon profile={profile} colorPalette={color_palette} />
                    : ''
                }
                </div>
              </div>
            </main>
            {/* Main Content End */}
          </div>
        </DocumentTitle>
      );
    }

    return (
      <div className="videos">
        {videosContent}
      </div>
    );
  }
}

Videos.defaultProps = {
  loading: false,
};

Videos.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  profile: state.profile,
  videos: state.profile.videos,
});

export default connect(mapStateToProps, { getVideos })(Videos);
