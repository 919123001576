import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import 'core-js/features/promise';
import 'core-js/features/object/assign';
import 'core-js/features/array/flat';
import 'core-js/features/array/find';
import ModalRoot from './components/common/Modals/ModalRoot';
import NavBar from './components/common/layout/NavBar/NavBar';
import Profile from './components/profile/Profile';
import Videos from './components/videos/Videos';
import Newsletters from './components/newsletters/Newsletters';
import Reviews from './components/reviews/Reviews';
import Footer from './components/common/layout/Footer/Footer';
import Contact from './components/contact/Contact';
import ContactRefer from './components/contact/ContactRefer';
import Subscribe from './components/subscribe/Subscribe';
import Unsubscribe from './components/unsubscribe/Unsubscribe';
import SingleNewsletter from './components/singleNewsletter/SingleNewsletter';
import KWR from './components/kellerWilliamsRecruting/KWR';
import Chat from './components/common/chatWindow/Chat';
import { getProfile } from './actions/profileActions';
import { returnCtaFormParams, includes, isBrowserSupported } from './util/helperFuncs';
import { campaignPaths } from './util/formTypeConfig';
import Spinner from './components/common/Spinner';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import CustomPage from './components/customPage/CustomPage';
import { initGA, logPageView } from './components/common/Google/ReactGA';
import FeedbackSuccess from './components/feedbackSuccess/FeedbackSuccess';
import NotSupported from './components/browserNotSupported/NotSupported';
import SecretsActions from './actions/SecretsActions';
import SecretsService from './services/SecretsService';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsTagsAdded: false,
    };
  }

  componentDidMount() {
    this.loadProfileAndReCaptchaSiteKey();
    initGA();
    logPageView();
  }

  componentDidUpdate() {
    // NOTE: This function updates the state
    this.updateIndexHeadTag();
  }

  loadProfileAndReCaptchaSiteKey = () => {
    const { getProfile, secretsActions } = this.props;
    getProfile();
    secretsActions.getReCaptchaV2SiteKey();
  }

  updateIndexHeadTag() {
    // Insert Analytics Tag
    const { analyticsTagsAdded } = this.state;
    if (analyticsTagsAdded) return;
    const { profile } = this.props;

    if (profile.profile && profile.profile.info) {
      const el = document.createElement('div');
      el.innerHTML = profile.profile.info.third_party_analytics;
      const scripts = el.getElementsByTagName('script');
      for (let i = 0, _i = scripts.length; i < _i; i++) {
        // The scripts inserted but not loaded unless we create a new script element like this
        const script = document.createElement('script');
        script.async = true;
        if (scripts[i].src) script.src = scripts[i].src;
        if (scripts[i].type) script.type = scripts[i].type;
        if (scripts[i].innerHTML) script.innerHTML = scripts[i].innerHTML;
        document.head.appendChild(script);
      }

      // Allow Custom Favicon
      const { favicon_ico_image } = profile.profile;

      if (favicon_ico_image) {
        const updateFavicon = () => {
          const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = favicon_ico_image;
          document.getElementsByTagName('head')[0].appendChild(link);
        };

        updateFavicon();
      }

      this.setState({ analyticsTagsAdded: true });
    }
  }

  renderLoadingPage = () => (
    <div className="app" data-toggle="collapse" data-target=".navbar-collapse.show">
      <div className="container">
        <div className="row spinner">
          <div className="col">
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  );

  renderEnabledProfilePageContentAndRoutes = () => {
    const {
      location: { pathname },
      profile: { profile },
    } = this.props;

    const { navigator: { userAgent } } = window;
    const campaignPath = includes(campaignPaths, pathname);
    const feedbackPath = includes(pathname, 'feedback');
    const isSupported = isBrowserSupported(userAgent);

    const { show_reviews, user: { vertical } } = profile;
    const formShowFlag = profile.info[returnCtaFormParams(pathname, vertical)];

    return (
      <div>
        {!feedbackPath && isSupported && <NavBar />}
        <Switch>
          {!isSupported && <Route component={NotSupported} />}
          <Route exact path="/" component={Profile} />
          <Route exact path="/videos" component={Videos} />
          <Route exact path="/newsletters" component={Newsletters} />
          {show_reviews && <Route exact path="/reviews" component={Reviews} />}
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/contact/refer" component={ContactRefer} />
          <Route exact path="/single-newsletter/:slug" component={SingleNewsletter} />
          <Route exact path="/single-newsletter/cc/:slug" component={SingleNewsletter} />
          <Route exact path="/unsubscribe/:campaignid/:contactid" component={Unsubscribe} />
          <Route exact path="/unsubscribe/:campaignid" component={Unsubscribe} />
          <Route exact path="/subscribe" component={Subscribe} />
          <Route exact path="/custompage" component={CustomPage} />
          <Route exact path="/p/:slug" component={CustomPage} />
          <Route exact path="/feedback/:campaignid/:contactid" component={FeedbackSuccess} />
          <Route exact path="/feedback/:campaignid" component={FeedbackSuccess} />
          <Route exact path="/subscribe" component={Subscribe} />
          <Route exact path="/joinourteam" component={KWR} />
          {pathname === '/refer' && formShowFlag && (
            <Route
              render={() => <Contact triggerTypeId="11" />}
            />
          )}
          {campaignPath && formShowFlag && (
            <Route
              path={pathname}
              render={() => <Contact triggerTypeId={returnCtaFormParams(pathname, vertical, campaignPath)} />}
            />
          )}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
        {!feedbackPath && isSupported && <Footer profile={profile} />}
      </div>
    );
  }

  renderDisabledProfilePageRoutes = () => (
    <Switch>
      <Route exact path="/unsubscribe/:campaignid/:contactid" component={Unsubscribe} />
      <Route exact path="/unsubscribe/:campaignid" component={Unsubscribe} />
      <Route component={Contact} />
    </Switch>
  );

  render() {
    const {
      location: { pathname },
      profile: { profile },
      loading,
      reCaptchaV2SiteKey,
    } = this.props;

    const isLoading = !profile || loading || !reCaptchaV2SiteKey;

    if (isLoading) {
      return this.renderLoadingPage();
    }

    const { navigator: { userAgent } } = window;
    const feedbackPath = includes(pathname, 'feedback');
    const isSupported = isBrowserSupported(userAgent);
    const isUnsubscribePage = includes(pathname, 'unsubscribe');

    const isProfilePage = profile.is_enabled;
    const chatEnabled = profile.info.fchat_enabled;

    const showChat = isProfilePage && chatEnabled && !feedbackPath && isSupported && !isUnsubscribePage;

    return (
      <div className="app" data-toggle="collapse" data-target=".navbar-collapse.show">
        <React.Fragment>
          {
            isProfilePage
              ? this.renderEnabledProfilePageContentAndRoutes()
              : this.renderDisabledProfilePageRoutes()
          }
          <ModalRoot />
        </React.Fragment>
        {showChat && <Chat profile={profile} />}
      </div>
    );
  }
}

App.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  reCaptchaV2SiteKey: PropTypes.string,
};

App.defaultProps = {
  reCaptchaV2SiteKey: null,
};

const mapStateToProps = state => ({
  profile: state.profile,
  reCaptchaV2SiteKey: state.secrets.reCaptchaV2SiteKey,
});

const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  secretsActions: new SecretsActions(dispatch, new SecretsService()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
