import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import NewslettersCommon from '../common/NewslettersCommon/NewslettersCommon';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';
import { getUnsubscribe, postUnsubscribeFormRequest, postResubscribeFormRequest } from '../../actions/profileActions';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../actions/types';
import { loadTitle, toggleProfileColorPalette, isOEDomain } from '../../util/helperFuncs';
import { initGA, logPageView } from '../common/Google/ReactGA';
import ReCaptchaV2 from '../common/Google/ReCaptchaV2';

class Unsubscribe extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    errors: {},
    showEmail: false,
    showTextarea: false,
    formValues: null,
  };

  componentDidMount = async () => {
    const unsubscribeUrl = this.props.match.url;
    await this.props.getUnsubscribe(unsubscribeUrl);
    initGA();
    logPageView();
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  submitForm = () => {
    const { formValues } = this.state;

    const onCreateSubmitObj = (reasons) => {
      const { postUnsubscribeFormRequest } = this.props;
      const { form_key, encrypted_unsubscribe_id } = this.state;

      const obj = {
        form_key,
        encrypted_unsubscribe_id,
        reasons,
      };

      return postUnsubscribeFormRequest(obj);
    };

    this.handleSubmitBtnClick(() => onCreateSubmitObj(formValues, false));
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  onSubmitResub = (values) => {
    const onCreateSubmitObj = (values) => {
      const { postResubscribeFormRequest } = this.props;
      const { form_key, encrypted_unsubscribe_id } = this.state;
      const obj = {
        form_key: '',
        form_data: {},
      };

      obj.form_key = form_key;
      obj.form_data = Object.assign(obj.form_data, values);

      return postResubscribeFormRequest(encrypted_unsubscribe_id, obj);
    };

    this.handleSubmitBtnClick(() => onCreateSubmitObj(values, true));
  }

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div>
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderCheckboxField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="checkbox mx-auto">
        <label htmlFor={field.for} className="control-label">
          <input
            {...field.input}
            type={field.type}
            id={field.id}
            className={field.className || ''}
          /><i className="helper" />{field.label}
        </label>
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  renderTextAreaField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;

    return (
      <div>
        <textarea
          {...field.input}
          id={field.id}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label pl-5">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  showEmail = () => this.setState(prevState => ({
    showEmail: !prevState.showEmail,
  }));

  showTextarea = () => this.setState(prevState => ({
    showTextarea: !prevState.showTextarea,
  }));

  clearCheckbox = () => {
    document.getElementById('unsubEmail').checked = false;
    document.getElementById('unsubTextarea').checked = false;
  }

  handleSubmitBtnClick = (callback, showModalNeeded) => {
    const { showModal, reset, profile: { profile, unsubscribe } } = this.props;
    // Get form key from the profile
    // Also set button back to disabled
    this.setState({
      form_key: profile.form_key,
      encrypted_unsubscribe_id: unsubscribe.encrypted_unsubscribe_id,
    }, callback);

    if (showModalNeeded) {
      // Allow simultaneous modal trigger and reading of form field values
      // Sets a buffer timeout to allow values to be read before resetting form fields
      // Manually reset dropdown fields as the reset() only clears all other input fields
      showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
    }

    setTimeout(() => {
      reset();
      this.setState({ btnDisabled: true, showEmail: false, showTextarea: false });
      this.clearCheckbox();
    }, 1000);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      profile: {
        profile,
        profile: { color_palette },
        loading,
        unsubscribe,
      },
    } = this.props;

    const { btnDisabled, showEmail, showTextarea } = this.state;

    const url = profile.user.landing_page_host;

    const unsubscribeContent = loading
      ? (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <DocumentTitle title={loadTitle(profile.page_title, 'Unsubscribed')}>
          <div className="unsubscribe">
            <HeaderSmall profile={profile}>
              <Greeting title="Unsubscribed" subheading="" />
            </HeaderSmall>
            <div className="text-center">
              {unsubscribe && unsubscribe.unsubscribe_email && (
                <span className="unsubscribedEmail">{unsubscribe.unsubscribe_email}&nbsp;is now unsubscribed.</span>
              )}
              <h5>Sorry to see you go. Quick favor? Would you please tell me why you unsubscribed?</h5>
              <h6 className="font-weight-light">(Optional)</h6>
            </div>
            <div className="container">
              <form id="unsubscribe" name="unsubscribe" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                <React.Fragment>
                  <Field
                    type="checkbox"
                    name="content"
                    id="unsubscribe1"
                    component={this.renderCheckboxField}
                    label="I didn't like the content"
                    value="I didn't like the content"
                  />
                </React.Fragment>
                <React.Fragment>
                  <Field
                    type="checkbox"
                    name="too many"
                    id="unsubscribe2"
                    component={this.renderCheckboxField}
                    label="I get too many emails from you"
                    value="I get too many emails from you"
                  />
                </React.Fragment>
                <React.Fragment>
                  <Field
                    type="checkbox"
                    name="who are you"
                    id="unsubscribe3"
                    component={this.renderCheckboxField}
                    label="I don't know who you are"
                    value="I don't know who you are"
                  />
                </React.Fragment>
                <React.Fragment>
                  <div className="checkbox mx-auto">
                    <label>
                      <input id="unsubEmail" type="checkbox" onChange={this.showEmail} /><i className="helper" />I'd like emails sent to a different address
                    </label>
                  </div>
                  {showEmail && (
                  <div className="form-group unsubscribe-hidden mx-auto">
                    <Field
                      type="email"
                      name="different address"
                      id="email"
                      component={this.renderField}
                      for="email"
                      className=""
                      label="Email Address"
                      required
                    />
                  </div>
                  )}
                </React.Fragment>
                <React.Fragment>
                  <div className="checkbox mx-auto">
                    <label>
                      <input id="unsubTextarea" type="checkbox" onChange={this.showTextarea} /><i className="helper" />Other
                    </label>
                  </div>
                  {showTextarea && (
                    <div className="form-group unsubscribe-hidden mx-auto">
                      <Field
                        type="text"
                        name="other"
                        component={this.renderTextAreaField}
                        className=""
                      />
                    </div>
                  )}
                </React.Fragment>
                {isOEDomain(url) && (
                  <ReCaptchaV2
                    onVerify={this.submitForm}
                    reCaptchaRef={this.reCaptchaRef}
                  />
                )}
                <div className="row">
                  <div className="col text-center submit-btn-wrapper mb-5">
                    <button
                      className="contact-btn btn btn-large"
                      type="submit"
                      name="action"
                      disabled={invalid || submitting || (isOEDomain(url) && btnDisabled) || btnDisabled}
                      style={toggleProfileColorPalette(profile, 'contactButton')}
                    >
                      Submit Feedback
                    </button>
                  </div>
                </div>
              </form>
              <div className="text-center pb-3 mt-4">
                <h5>Did you unsubscribe by accident? Don't want to miss emails like these?</h5>
                <h6>No worries, just re-subscribe here</h6>
                <form id="resubscribeV2" name="resubscribeV2" onSubmit={handleSubmit(this.onSubmitResub)} className="md-form" noValidate>
                  <div className="form-group resub mx-auto">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      component={this.renderField}
                      for="email"
                      className=""
                      label="Email Address"
                      required
                    />
                  </div>
                  <div className="resub-btn">
                    <button
                      className="contact-btn btn btn-large"
                      type="submit"
                      name="action"
                      disabled={invalid || submitting}
                      style={toggleProfileColorPalette(profile, 'contactButton')}
                    >
                      Resubscribe
                    </button>
                  </div>
                </form>
              </div>
              <div className="mt-5 pt-5">
                {profile.newsletters && <NewslettersCommon profile={profile} colorPalette={color_palette} />}
                {profile.reviews && <ReviewsCommon profile={profile} colorPalette={color_palette} />}
              </div>
            </div>
          </div>
        </DocumentTitle>
      );

    return (
      <div className="contact">
        {unsubscribeContent}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.other && (values.other.includes('<') || values.other.includes('&lt;'))) {
    errors.other = 'Invalid text entered';
  }
  if (values.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  if (values['different address'] && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values['different address'])) {
    errors['different address'] = 'Valid email required.';
  }

  return errors;
};

Unsubscribe.defaultProps = {
  loading: false,
};

Unsubscribe.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

const mapDispatchToProps = {
  getUnsubscribe,
  showModal,
  postUnsubscribeFormRequest,
  postResubscribeFormRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ validate, form: 'UnsubscribeForm' }),
)(Unsubscribe);
