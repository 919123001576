import {
  SHOW_MODAL,
  HIDE_MODAL,
} from './types';

// List actions needed for profile as exportable pure functions
export const showModal = (type, props) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    props,
  },
});

// Hide Modal
export const hideModal = () => ({
  type: HIDE_MODAL,
});
