import React from 'react';

const VideoItem = (props) => {
  const { videoLink, title } = props;

  return (
    <div className="col-sm-6 video-item">
      <h3 className="video-title">{title}</h3>
      <iframe
        title="userVideo"
        id="player"
        type="text/html"
        width="100%"
        height="300"
        src={videoLink}
        frameBorder="0"
      />
    </div>
  );
};

export default VideoItem;
