import React from 'react';
import Parser from 'html-react-parser';
import ChatWindow from './ChatWindow';
import ChatForm from './ChatForm';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideForm: false,
      showChat: true,
    };

    this.showSlideForm = this.showSlideForm.bind(this);
    this.closeChat = this.closeChat.bind(this);
  }

  showSlideForm() {
    this.setState({ slideForm: true });
  }

  closeChat() {
    this.setState({ showChat: false });
  }

  render() {
    const {
      profile: {
        color_palette, full_name, info: {
          fchat_delay, fchat_message, fchat_response,
        },
      }, profile,
    } = this.props;
    const { slideForm, showChat } = this.state;

    if (!profile) {
      return null;
    }
    return (
      <div>
        {
          showChat
            ? (
              <div>
                {slideForm ? (
                  <ChatForm
                    profile={profile}
                    closeChat={this.closeChat}
                  />
                ) : null}
                <ChatWindow
                  colorPalette={color_palette}
                  profileName={full_name && Parser(full_name)}
                  headshotImage={profile.headshot_image}
                  chatHeading={fchat_message && Parser(fchat_message)}
                  awayMessage={fchat_response && Parser(fchat_response)}
                  popupDelay={fchat_delay}
                  showSlideForm={this.showSlideForm}
                  closeChat={this.closeChat}
                />
              </div>
            ) : ''
      }
      </div>
    );
  }
}

export default Chat;
