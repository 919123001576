import React from 'react';

const Spinner = () => (
  <div className="row mt-5">
    <div className="col" />
    <div className="col text-center my-5">
      <i data-testid="spinner" className="fas fa-sync fa-spin fa-6x" />
    </div>
    <div className="col" />
  </div>
);

export default Spinner;
