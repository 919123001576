import Parser from 'react-html-parser';
import { verticalList } from './formTypeConfig';

export function truncateString(str) {
  if (!str) return null;
  const result = str.length <= 200 ? `${str.substr(0, 200)}` : `${str.substr(0, 200)}...`;
  return result;
}

export function getVideoLinkAndTitle(video) {
  const { title } = video;
  const link = Parser(video.link).flat().map(prop => prop.props.src)[0];
  return { link, title };
}

export function loadTitle(title, defaultRoute, route) {
  // title should be 'profile.page_title' when called
  if (title === null || title === '' || title === undefined) {
    return route = defaultRoute;
  }
  if (route === undefined) {
    route = defaultRoute;
    return `${title} - ${route}`;
  }
  return `${title} - ${route}`;
}

export function returnCtaFormParams(pathname, verticalId, campaignPath = null) {
  let triggerTypeId;
  let formShowFlag;

  const ctaForms = returnUserVertical(verticalId);

  ctaForms.forEach((form) => {
    if (form.path === pathname) {
      triggerTypeId = form.value.toString();
      formShowFlag = form.showFlag;
    }
  });

  if (campaignPath) {
    return triggerTypeId;
  }
  return formShowFlag;
}

function returnUserVertical(verticalId) {
  const userVertical = verticalList.filter(vert => vert.verticalId === verticalId);
  return userVertical[0].options;
}

export function toggleProfileColorPalette(profile, type) {
  const { color_palette } = profile;

  let result;
  if (color_palette) {
    switch (type) {
      case 'navbar':
        result = { backgroundColor: `${color_palette.dark_primary_color}` };
        break;
      case 'navbarHover':
        result = { '&:hover': { backgroundColor: `${color_palette.dark_primary_color}` } };
        break;
      case 'footer':
        result = { backgroundColor: `${color_palette.dark_primary_color}` };
        break;
      case 'footerSubscribeButton':
        result = { backgroundColor: `${color_palette.primary_color}` };
        break;
      case 'link':
        result = { color: `${color_palette.primary_color}` };
        break;
      case 'divider':
        result = { border: `1.1px solid ${color_palette.primary_color}` };
        break;
      case 'contactButton':
        result = { backgroundColor: `${color_palette.primary_color}` };
        break;
      case 'leaveReviewButton':
        result = { backgroundColor: `${color_palette.primary_color}` };
        break;
      case 'cta':
        result = { color: `${color_palette.primary_color}` };
        break;
      default:
        break;
    }
  }

  return result;
}

export function togglePropsColorPalette(props, type) {
  const { colorPalette } = props;
  let result;
  if (colorPalette) {
    switch (type) {
      case 'link':
        result = { color: `${colorPalette.primary_color}` };
        break;
      case 'divider':
        result = { border: `1.1px solid ${colorPalette.primary_color}` };
        break;
      case 'cardHeaderBG':
        result = { backgroundColor: `${colorPalette.primary_color}` };
        break;
      case 'contactButton':
        result = { backgroundColor: `${colorPalette.primary_color}` };
        break;
      case 'cta':
        result = { color: `${colorPalette.primary_color}` };
        break;
      default:
        break;
    }
  }

  return result;
}

export const includes = (container, value) => container.indexOf(value) >= 0;

export function isBrowserSupported(userAgent) {
  let isSupported = true;
  const userAgentMSIE = includes(userAgent, 'MSIE');
  const userAgentTrident = includes(userAgent, 'Trident');

  if (userAgentMSIE || userAgentTrident) {
    isSupported = false;
  }

  return isSupported;
}

const oeDomains = [
  'localhost',
  'outboundengine.com',
  'oestaging.com',
  'ourhomehq.biz',
  'myhomehq.biz',
  'ourpromohq.biz',
  'mypromohq.biz',
  'outboundengine.staging.wpengine.com',
  'outbounddev.staging.wpengine.com',
  'ourmobilehq.biz',
  'outboundengine.reviews',
  'promopeach.net',
  'myhealthhq.biz',
  'oetest.org',
];

export const isOEDomain = url => oeDomains.find(customDomain => url.indexOf(customDomain) >= 0);


export function validateEmail(email) {
  let result;
  if (!email) {
    result = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(email)) {
    result = 'Valid email required.';
  }
  return result;
}
