import React from 'react';
import { Link } from 'react-router-dom';

const NewslettersItem = (props) => {
  const {
    campaignId, imageUrl, campaignName, shareDescription, campaignDate, onlineUrl, linkColor,
  } = props;


  return (
    <div className="newsletters col-sm-4 p-3">
      <div data-testid="newsletter-campaign-id" key={campaignId} className="card h-100">
        <Link to={`/single-newsletter/${onlineUrl}`}><img className="card-img-top" data-testid="newsletter-card-image" src={imageUrl || 'https://outboundengine.s3.amazonaws.com/resources/images/custom-campaign-newsletter-thumb.jpg'} alt="newsletter" /></Link>
        <div className="card-body">
          <h3 data-testid="newsletter-campaign-name" className="card-title text-uppercase">{campaignName}</h3>
          <p data-testid="newsletter-share-description" className="card-text-main">{shareDescription}</p>
        </div>
        <div data-testid="newsletter-card-footer" className="card-footer">
          <small data-testid="newsletter-card-footer-campaign-date" className="text-muted">{campaignDate}</small>
          <span data-testid="newsletter-card-url" className="read-more float-right"><Link to={`/single-newsletter/${onlineUrl}`} style={linkColor}>...read more</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewslettersItem;
