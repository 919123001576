import React from 'react';
import { connect } from 'react-redux';

// Specific Modal Components
import ContactGeneralFormModal from './ContactGeneralFormModal';
import ReviewItemModal from './ReviewItemModal';
import ReviewFormModal from './ReviewFormModal';
import SubmitSuccessModal from './SubmitSuccessModal';
import SubscribeModal from './SubscribeModal';

// Modal Types
import {
  MODAL_TYPE_CONTACT_GENERAL_FORM,
  MODAL_TYPE_REVIEW_ITEM,
  MODAL_TYPE_REVIEW_FORM,
  MODAL_TYPE_SUBMIT_SUCCESS,
  MODAL_TYPE_SUBSCRIBE_FORM,
} from '../../../actions/types';

// Modal Type Constants - Assign a constant to the template to be used for that modal
const MODAL_COMPONENTS = {
  [MODAL_TYPE_CONTACT_GENERAL_FORM]: ContactGeneralFormModal,
  [MODAL_TYPE_REVIEW_ITEM]: ReviewItemModal,
  [MODAL_TYPE_REVIEW_FORM]: ReviewFormModal,
  [MODAL_TYPE_SUBMIT_SUCCESS]: SubmitSuccessModal,
  [MODAL_TYPE_SUBSCRIBE_FORM]: SubscribeModal,
};

const ModalRoot = ({ type, props }) => {
  if (!type) { // no modal type set in store
    return null;
  }

  // If modal type set in store...
  const ModalComponent = MODAL_COMPONENTS[type];
  //  ...render that modal
  return <ModalComponent {...props} />;
};

export default connect(state => state.modal)(ModalRoot);
