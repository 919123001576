import React from 'react';
import ChatList from './ChatList';
import ChatInput from './ChatInput';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

const uuidv1 = require('uuid/v1');

class ChatWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.messageResponse = this.messageResponse.bind(this);
    this.setId = this.setId.bind(this);
  }

  setId(obj) {
    const id = uuidv1();
    const idObj = {
      id,
      message: obj.message,
      user: obj.user,
    };
    this.handleMessageSubmit(idObj);
  }

  handleMessageSubmit(message) {
    const { profileName } = this.props;
    const chatDiv = document.getElementById('chat-list');
    this.setState((prevState) => {
      const newMessages = [...prevState.messages];
      newMessages.push(message);
      return { messages: newMessages };
    });

    chatDiv.scrollTop = chatDiv.scrollHeight - chatDiv.clientHeight;

    if (message.user === profileName) {
      this.slideFormTrue();
    }
  }

  messageResponse() {
    const { awayMessage, profileName } = this.props;
    const messageResponseObj = {
      message: awayMessage,
      user: profileName,
    };
    this.setId(messageResponseObj);
  }

  slideFormTrue() {
    const { showSlideForm } = this.props;
    setTimeout(() => { showSlideForm(); }, 3000);
  }

  render() {
    const {
      profileName,
      headshotImage,
      chatHeading,
      closeChat,
      popupDelay,
      colorPalette,
    } = this.props;

    const { messages } = this.state;

    const chatDelay = { animationDelay: `${popupDelay}s` };

    return (
      <div className="container">
        <div className="card chat-card chat-window" style={chatDelay}>
          <div className="card-header" style={togglePropsColorPalette(this.props, 'cardHeaderBG')}>
            {profileName}
            <button type="button" className="close" onClick={closeChat}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <div className="row chat-image-heading">
              {
              headshotImage
              && (
              <div className="chat-image col-3">
                <img
                  src={headshotImage}
                  className="mt-3 mr-1"
                  alt=""
                />
              </div>
              )
            }
              <div className={headshotImage ? 'col chat-header' : 'col chat-header-no-image'}>
                {chatHeading}
              </div>
            </div>
            <ChatList messages={messages} />
            <ChatInput
              onMessageSubmit={this.setId}
              messageResponse={this.messageResponse}
              colorPalette={colorPalette}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ChatWindow;
