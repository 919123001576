import { GET_RECAPTCHA_V3_SITE_KEY, GET_RECAPTCHA_V2_SITE_KEY } from '../actions/types';

const initialState = {
  reCaptchaV3SiteKey: null,
  reCaptchaV2SiteKey: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECAPTCHA_V3_SITE_KEY: {
      return {
        ...state,
        reCaptchaV3SiteKey: action.payload,
      };
    }
    case GET_RECAPTCHA_V2_SITE_KEY: {
      return {
        ...state,
        reCaptchaV2SiteKey: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
