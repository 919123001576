import {
  GET_PROFILE,
  GET_HOME,
  GET_REVIEWS,
  POST_CONTACT_REQUEST,
  POST_REVIEW_REQUEST,
  GET_SINGLE_NEWSLETTER,
  LOADING,
  GET_VIDEOS,
  GET_NEWSLETTERS,
  GET_UNSUBSCRIBE,
  POST_RESUBSCRIBE_REQUEST,
  GET_FEEDBACKSUCCESS,
  GET_CUSTOMPAGE,
} from '../actions/types';

const initialState = {
  profile: null,
  videos: null,
  reviews: null,
  contact: null,
  newsletters: null,
  singlenewsletter: null,
  unsubscribe: null,
  resubscribe: null,
  custompage: null,
  loading: false,
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };

    case GET_HOME:
      return {
        ...state,
        home: action.payload,
        loading: false,
      };

    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };

    case POST_CONTACT_REQUEST:
      return {
        ...state,
        contact: action.payload,
      };

    case POST_REVIEW_REQUEST:
      return {
        ...state,
        review: action.payload,
      };

    case POST_RESUBSCRIBE_REQUEST:
      return {
        ...state,
        resubscribe: action.payload,
      };

    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
        loading: false,
      };

    case GET_NEWSLETTERS:
      return {
        ...state,
        newsletters: action.payload,
        loading: false,
      };

    case GET_SINGLE_NEWSLETTER:
      return {
        ...state,
        singlenewsletter: action.payload,
        loading: false,
      };

    case GET_UNSUBSCRIBE:
      return {
        ...state,
        unsubscribe: action.payload,
        loading: false,
      };

    case GET_FEEDBACKSUCCESS:
      return {
        ...state,
        feedbackSuccess: action.payload,
        loading: false,
      };

    case GET_CUSTOMPAGE:
      return {
        ...state,
        custompage: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
