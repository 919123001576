import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { Field, reduxForm } from 'redux-form';
import { HeaderSmall, Greeting } from '../common/layout/HeaderSmall';
import NewslettersSubscribe from './NewslettersSubscribe';
import ReviewsCommon from '../common/ReviewsCommon/ReviewsCommon';
import Spinner from '../common/Spinner';
import { postProfileFormRequest, getProfile } from '../../actions/profileActions';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_SUBMIT_SUCCESS } from '../../actions/types';
import { initGA, logPageView } from '../common/Google/ReactGA';
import { loadTitle, toggleProfileColorPalette, isOEDomain } from '../../util/helperFuncs';
import ReCaptchaV2 from '../common/Google/ReCaptchaV2';

class Subscribe extends Component {
  state = {
    btnDisabled: false,
    form_key: '',
    errors: {},
    formValues: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  submitForm = async () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = async (values) => {
      const { showModal } = this.props;
      const { profile: { contact, campaign_id } } = this.props.profile;
      const { form_key } = this.state;
      const obj = {
        form_key: '',
        form_name: '',
        lead_trigger_type_id: null,
        form_data: {},
      };

      const form = document.getElementsByTagName('form');
      const formName = form[0].name;
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.lead_trigger_type_id = 6;
      delete values.lead_trigger_type_id; // Remove lead_trigger_type_id from payload
      obj.form_data = Object.assign(obj.form_data, values);
      obj.form_name = formName;

      await this.props.postProfileFormRequest(obj);

      showModal(MODAL_TYPE_SUBMIT_SUCCESS, {});
    };

    await onCreateSubmitObj(values);
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  renderField = (field) => {
    const { meta: { touched, error } } = field;
    const textHelp = `error ${touched && error ? 'danger' : ''}`;
    return (
      <div className="form-group">
        <input
          {...field.input}
          type={field.type}
          id={field.id}
          className={field.className || ''}
          required={field.required}
        />
        <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        <p className={textHelp}>{ touched && error ? error : '' }</p>
      </div>
    );
  }

  render() {
    const { profile, profile: { color_palette }, loading } = this.props.profile;
    const {
      handleSubmit,
      submitting,
      invalid,
      reset,
    } = this.props;

    const { btnDisabled } = this.state;

    const url = profile.user.landing_page_host;
    const isCustomDomain = !isOEDomain(url);

    let subscribeContent;

    const handleSubmitBtnClick = () => {
      // Get form key from the profile
      this.setState({ form_key: profile.form_key }); // Also set button back to disabled

      // Function to allow simultaneous modal trigger and reading of form field values
      // Sets a buffer timeout to allow values to be read before resetting form fields
      // Manually reset dropdown fields as the reset() only clears all other input fields
      const withBufferTimeout = () => {
        setTimeout(() => {
          reset();
          this.setState({ btnDisabled: true });
        }, 1000);
      };
      withBufferTimeout();
    };

    if (loading) {
      subscribeContent = (
        <div className="container">
          <div className="row">
            <div className="col">
              <Spinner />
            </div>
          </div>
        </div>
      );
    } else {
      initGA();
      logPageView();
      subscribeContent = (
        <DocumentTitle title={loadTitle(profile.page_title, 'Subscribe')}>
          <div>
            <div className="text-center mb-4">
              <HeaderSmall profile={profile}>
                <Greeting title="Subscribe" subheading="" />
              </HeaderSmall>
              <h5 className="font-weight-light">Subscribe to my Newsletter</h5>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-8 mx-auto mt-5 mb-5">
                  <small>* = required fields</small>
                  <form id="SubscribeForm" name="SubscribeForm" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                    <React.Fragment>
                      <div className="container">
                        <div className="row">
                          <Field
                            type="text"
                            name="first_name"
                            id="first-name"
                            component={this.renderField}
                            for="first_name"
                            className=""
                            label="First Name*"
                            required
                          />
                          <Field
                            type="text"
                            name="last_name"
                            id="last-name"
                            component={this.renderField}
                            for="last_name"
                            className=""
                            label="Last Name"
                            required
                          />
                        </div>
                      </div>
                    </React.Fragment>
                    <React.Fragment>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        component={this.renderField}
                        for="email"
                        className=""
                        label="Email Address*"
                        required
                      />
                    </React.Fragment>
                    {!isCustomDomain && (
                      <ReCaptchaV2
                        onVerify={this.submitForm}
                        reCaptchaRef={this.reCaptchaRef}
                      />
                    )}
                    <div className="row">
                      <div className="col text-center submit-btn-wrapper mb-5 mt-1">
                        <button
                          className="contact-btn btn btn-large"
                          type="submit"
                          name="action"
                          onClick={handleSubmitBtnClick}
                          disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                          style={toggleProfileColorPalette(profile, 'contactButton')}
                        >Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-5">
                { profile.newsletters
                  ? <NewslettersSubscribe profile={profile} colorPalette={color_palette} />
                  : ''
            }
                { profile.reviews
                  ? <ReviewsCommon profile={profile} colorPalette={color_palette} />
                  : ''
            }
              </div>
            </div>
          </div>
        </DocumentTitle>
      );
    }
    return (
      <div className="contact">
        {subscribeContent}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.lead_trigger_type_id) {
    errors.lead_trigger_type_id = 'Please choose a valid option';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (values.first_name && (values.first_name.includes('<') || values.first_name.includes('&lt;'))) {
    errors.first_name = 'Invalid text entered';
  }
  if (values.last_name && (values.last_name.includes('<') || values.last_name.includes('&lt;'))) {
    errors.last_name = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }

  return errors;
};

Subscribe.defaultProps = {
  loading: false,
};

Subscribe.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default compose(
  connect(mapStateToProps, { getProfile, postProfileFormRequest, showModal }),
  reduxForm({ validate, form: 'SubscribeForm' }),
)(Subscribe);
