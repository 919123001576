import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import StarRatingComponent from 'react-star-rating-component';
import { hideModal } from '../../../actions/modalActions';
import Modal from './Modal';


const ReviewItemModal = ({
  profile, headshot, fullName, company, reviewSite, rating, review, firstName, lastName, city, state, date, afterClose, hideModal,
}) => {
  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };
  const companyLogos = [
    {
      name: 'FACEBOOK',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/facebook_logo.png',
    },
    {
      name: 'OUTBOUNDENGINE',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/oe_logo_trans_50.png',
    },
    {
      name: 'GOOGLE_REVIEWS',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/googlereviews_logo.png',
    },
    {
      name: 'YELP',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/yelp_logo.png',
    },
    {
      name: 'ZILLOW',
      logo_url: 'https://s3.amazonaws.com/outboundengine/resources/images/reviews/zillow_logo.png',
    },
  ];

  const setLogo = (reviewCompanies, site) => {
    let logo;
    companyLogos.map(reviewCompany => (reviewCompany.name === site ? logo = reviewCompany.logo_url : null));
    return logo;
  };


  return (
    <Modal
      fullName={fullName}
      company={company}
      headshot={headshot}
      reviewSite={reviewSite}
      rating={rating}
      review={review}
      firstName={firstName}
      lastName={lastName}
      city={city}
      state={state}
      date={date}
      onClose={onClose}
    >
      <div className="col review-card">
        <div className="flex-row-reverse">
          <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="row">
          <div className="col text-center">
            {
              headshot && (
              <div className="headshot-image mx-auto">
                <img
                  src={headshot}
                  alt={Parser(fullName)}
                />
              </div>
              )
            }
            <div className="header-info">
              <p className="client-name">{fullName && Parser(fullName)} {fullName && company ? ' - ' : '' } {company && Parser(company)}</p>
            </div>
          </div>
        </div>
        <div className="card" data-toggle="modal" data-target="#review-modal">
          <div className="card-body">
            <img className="card-logo" src={setLogo(companyLogos, reviewSite)} alt="" />
            <StarRatingComponent
            // className="star-rating"
              name="review-rating"
              starCount={5}
              renderStarIcon={() => <i className="fas fa-star" />}
              value={rating}
              editing={false}
            />
            <p className="review-text">{review ? Parser(review) : ''}</p>
            <p className="reviewer-info">{firstName && Parser(firstName)} {lastName && `${Parser(lastName).slice(0, 1)}.`} {firstName && city ? ' -' : ''} {city && Parser(city)}{city && state ? ',' : ''} {firstName && !city && state ? '- ' : ''}{state && Parser(state)}</p>
            <span data-testid="review-date" className="review-date">{date && date}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReviewItemModal.defaultProps = {
  city: '',
  state: '',
  review: '',
  firstName: null,
  lastName: null,
  headshot: null,
  company: null,
};

ReviewItemModal.propTypes = ({
  fullName: PropTypes.string.isRequired,
  company: PropTypes.string,
  headshot: PropTypes.string,
  reviewSite: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  review: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  date: PropTypes.string.isRequired,
});

export default connect(null, { hideModal })(ReviewItemModal);
