import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { postProfileFormRequest } from '../../../actions/profileActions';
import normalizePhone from '../../../util/normalizePhone';
import ChatSuccess from './ChatSuccess';
import { toggleProfileColorPalette, isOEDomain } from '../../../util/helperFuncs';
import ReCaptchaV2 from '../Google/ReCaptchaV2';

class ChatForm extends React.Component {
  state = {
    btnDisabled: false,
    form_key: '',
    errors: {},
    showSuccess: false,
    formValues: null,
  }

  reCaptchaRef = React.createRef();

  validateUser = () => {
    const url = this.props.profile.profile.user.landing_page_host;

    if (!isOEDomain(url)) {
      this.submitForm();
    } else if (this.reCaptchaRef.current) {
      this.reCaptchaRef.current.execute();
    }
  }

  onSubmit = (formValues) => {
    this.setState(
      { formValues },
      this.validateUser,
    );
  }

  submitForm = () => {
    const { formValues: values } = this.state;

    const onCreateSubmitObj = (values) => {
      const { profile: { contact, campaign_id } } = this.props.profile;
      const { form_key } = this.state;
      const obj = {
        form_key: '',
        form_name: '',
        lead_trigger_type_id: null,
        form_data: {},
      };

      const formName = 'chatV2';
      obj.form_key = form_key;
      // Add contact and campaign IDs if they exist via cookie from server
      if (contact) {
        obj.contact_id = Number(contact.id);
      }
      if (campaign_id) {
        obj.campaign_id = Number(campaign_id);
      }
      obj.lead_trigger_type_id = 7;
      delete values.lead_trigger_type_id; // Remove lead_trigger_type_id from payload
      obj.form_data = Object.assign(obj.form_data, values);
      obj.form_name = formName;

      this.props.postProfileFormRequest(obj);

      setTimeout(() => { this.showSuccess(); }, 500);
    };

    onCreateSubmitObj(values);
  }

  showSuccess() {
    this.setState({ showSuccess: true });
  }

      renderField = (field) => {
        const { meta: { touched, error } } = field;
        const textHelp = `error ${touched && error ? 'danger' : ''}`;
        return (
          <div className="form-group chat-text-field">
            <input
              {...field.input}
              type={field.type}
              id={field.id}
              className={field.className || ''}
              required={field.required}
            />
            <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
            <p className={textHelp}>{ touched && error ? error : '' }</p>
          </div>
        );
      }

      renderPhoneField = field => (
        <div className="form-group chat-phone-field">
          <input
            {...field.input}
            type={field.type}
            id={field.id}
            className={field.className || ''}
            required={field.required}
          />
          <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
        </div>
      );

      renderTextAreaField = (field) => {
        const { meta: { touched, error } } = field;
        const textHelp = `error ${touched && error ? 'danger' : ''}`;

        return (
          <div className="form-group chat-message-field">
            <textarea
              {...field.input}
              type="text"
              id={field.id}
              className={field.className || ''}
              required={field.required}
            />
            <label htmlFor={field.for} className="control-label">{field.label}</label><i className="bar" />
            <p className={textHelp}>{ touched && error ? error : '' }</p>
          </div>
        );
      }

      render() {
        const { profile, profile: { color_palette } } = this.props.profile;
        const {
          handleSubmit,
          submitting,
          invalid,
          closeChat,
        } = this.props;
        const { btnDisabled } = this.state;

        const url = profile.user.landing_page_host;
        const isCustomDomain = !isOEDomain(url);

        const handleSubmitBtnClick = () => {
          // Get form key from the profile
          this.setState({ form_key: profile.form_key });
        };
        return (
          <div className="container chat-form">
            <div className="card chat-card slide-in-form">
              {
                this.state.showSuccess
                  ? <ChatSuccess closeChat={closeChat} colorPalette={color_palette} />
                  : (
                    <div className="card-body">
                      <div className="mb-2">
                        <h6 className="chat-form-header">Sorry I missed you! Please leave me a message.</h6>
                      </div>
                      <div>
                        <form id="chatV2" name="chatV2" onSubmit={handleSubmit(this.onSubmit)} className="md-form" noValidate>
                          <div className="container">
                            <div className="row">
                              <React.Fragment>
                                <Field
                                  type="email"
                                  name="email"
                                  id="email"
                                  component={this.renderField}
                                  for="email"
                                  className=""
                                  label="Email Address*"
                                  required
                                />
                              </React.Fragment>
                            </div>
                          </div>
                          <React.Fragment>
                            <div className="container">
                              <div className="row">
                                <Field
                                  type="text"
                                  name="name"
                                  id="chat-name"
                                  component={this.renderField}
                                  for="name"
                                  className=""
                                  label="Name*"
                                  required
                                />
                              </div>
                            </div>
                          </React.Fragment>
                          <React.Fragment>
                            <div className="container">
                              <div className="row">
                                <Field
                                  type="tel"
                                  name="phone"
                                  id="phone"
                                  component={this.renderPhoneField}
                                  for="phone"
                                  className=""
                                  label="Phone"
                                  normalize={normalizePhone}
                                  required
                                />
                              </div>
                            </div>
                          </React.Fragment>
                          <div className="container">
                            <div className="row">
                              <Field
                                type="text"
                                name="message"
                                id="general-inquiry"
                                component={this.renderTextAreaField}
                                for="message"
                                className="chat-textarea"
                                label="Add a message"
                                required
                              />
                            </div>
                          </div>
                          {!isCustomDomain && (
                            <ReCaptchaV2
                              onVerify={this.submitForm}
                              reCaptchaRef={this.reCaptchaRef}
                            />
                          )}
                          <div className="row">
                            <div className="col text-center submit-btn-wrapper mt-3">
                              <button
                                className="contact-btn btn btn-sm"
                                type="submit"
                                name="action"
                                onClick={handleSubmitBtnClick}
                                disabled={invalid || submitting || (!isCustomDomain && btnDisabled) || btnDisabled}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="By clicking 'Send Message', you agree to be contacted using the contact information you have provided above."
                                style={toggleProfileColorPalette(profile, 'contactButton')}
                              >Send Message
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )
            }
            </div>
          </div>
        );
      }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (values.name && (values.name.includes('<') || values.name.includes('&lt;'))) {
    errors.name = 'Invalid text entered';
  }
  if (values.message && (values.message.includes('<') || values.message.includes('&lt;'))) {
    errors.message = 'Invalid text entered';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = 'Valid email required.';
  }
  return errors;
};

ChatForm.defaultProps = {
  error: null,
};

ChatForm.propTypes = {
  profile: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  closeChat: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default compose(
  connect(mapStateToProps, { postProfileFormRequest }),
  reduxForm({ validate, form: 'chatV2' }),
)(ChatForm);
