import React from 'react';
import Message from './Message';

class ChatList extends React.Component {
  render() {
    const { messages } = this.props;
    return (
      <div className="chat" id="chat-list">
        {messages.map(message => (
          <Message
            message={message.message}
            user={message.user}
            key={message.id}
          />
        ),
        )}
      </div>
    );
  }
}

export default ChatList;
