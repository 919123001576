import { GET_RECAPTCHA_V3_SITE_KEY, GET_RECAPTCHA_V2_SITE_KEY } from './types';

export default class SecretsActions {
  dispatch = null;

  service = null;

  constructor(dispatch, service) {
    this.dispatch = dispatch;

    this.service = service;
  }

  getReCaptchaV3SiteKey = async () => {
    try {
      this.dispatch({
        type: GET_RECAPTCHA_V3_SITE_KEY,
        payload: await this.service.getReCaptchaV3SiteKey(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  getReCaptchaV2SiteKey = async () => {
    try {
      this.dispatch({
        type: GET_RECAPTCHA_V2_SITE_KEY,
        payload: await this.service.getReCaptchaV2SiteKey(),
      });
    } catch (error) {
      console.error(error);
    }
  }
}
