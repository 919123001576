import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewslettersItem from '../newsletters/NewslettersItem';
import { toggleProfileColorPalette } from '../../util/helperFuncs';

const NewslettersSubscribe = ({ profile, loading }) => (
  <div data-testid="newsletters-subcomponent" className="row subscribe-subhead">
    {
    profile.newsletters
      ? (
        <div>
          <div className="col header-links-component text-center">
            <h2>Newsletters</h2>
            <p className="pagelink text-center"><Link to="/newsletters" style={toggleProfileColorPalette(profile, 'link')}>See all Newsletters</Link></p>
          </div>
          <div className="container">
            <div className="row newsletters">
              {profile.newsletters.map(newsletter => (
                <NewslettersItem
                  key={newsletter.campaign.id}
                  campaignId={newsletter.campaign.id}
                  imageUrl={newsletter.image_url}
                  campaignName={newsletter.campaign.name}
                  shareDescription={newsletter.campaign.share_description}
                  campaignDate={newsletter.campaign_date}
                  onlineUrl={newsletter.campaign.newsletter_online_url}
                  linkColor={toggleProfileColorPalette(profile, 'link')}
                />
              ))}
            </div>
          </div>
        </div>
      ) : ''
  }
  </div>
  /* <!-- Newsletters End --> */
);

NewslettersSubscribe.propTypes = {
  profile: PropTypes.shape({
    newsletters: PropTypes.array,
  }).isRequired,
};

export default NewslettersSubscribe;
