import React from 'react';
import { togglePropsColorPalette } from '../../../util/helperFuncs';

const ChatSuccess = props => (
  <div className="container mt-4 chat-success">
    <div className="row">
      <div className="col-sm-12">
        <div className="check_mark">
          <div className="sa-icon sa-success animate">
            <span className="sa-line sa-tip animateSuccessTip" />
            <span className="sa-line sa-long animateSuccessLong" />
            <div className="sa-placeholder" />
            <div className="sa-fix" />
          </div>
        </div>
        <h2 className="text-center">Thank you for your submission!</h2>
      </div>
      <div className="col-sm-12 submit-btn-wrapper text-center mt-5">
        <button type="button" className="chat-close-btn btn btn-sm" onClick={props.closeChat} style={togglePropsColorPalette(props, 'contactButton')}>Close</button>
      </div>
    </div>
  </div>
);

export default ChatSuccess;
